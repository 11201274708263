
import { useNavigate, useParams } from 'react-router-dom';
import DeleteButton from '../../components/deletebutton/DeleteButton';
import { resourceTypes, useResource } from '../../hooks/resources';
import { ControlBar } from '../../components/controlbar/ControlBar';

import { Button, Form, Input, InputNumber, Select, Space, Switch, message } from 'antd';
import { useEffect } from 'react';
import { AgencyInput } from '../../components/agencies/AgencyInput';

import awsconfig from '../../amplifyconfiguration';
import { PaperClipOutlined } from '@ant-design/icons';

export default function ResourceForm() {
  const navigate = useNavigate();
  const { resourceId } = useParams();
  const [form] = Form.useForm();
  const [messageApi, messageContextHolder] = message.useMessage();

  const {
    resource,
    saveResource, deleteResource,
  } = useResource(resourceId || '');


  useEffect(() => {
    if (resource) {
      form.setFieldsValue({
        ...resource,
      });
    }
  }, [form, resource]);

  if (!resource) {
    return <div>Not found</div>;
  }

  const onFinish = (values) => {
    (async () => {
      await saveResource({
        ...resource,
        ...values,
      });
      messageApi.success('Enregistré');
    })().catch((err) => {
      messageApi.error(`Erreur: ${err}`);
    });
  };

  const onDelete = () => {
    if (resourceId) {
      (async () => {
        await deleteResource();
        messageApi.success('Supprimé');
        setTimeout(() => {
          navigate('/resources');
        }, 1000);
      })();
    }
  };

  const calendarUrl = `${awsconfig.API.REST.chronos.endpoint}/resources/${resourceId}/calendar`;

  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ flex: 1 }}
      labelWrap
      onFinish={onFinish}
    >
      {messageContextHolder}
      < fieldset className="box" >
        <legend>Informations</legend>

        {/* <div className="form-check form-switch form-check-reverse">
          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
            {...register("enabled", { className: "form-check-input" })} />
        </div> */}
        <Form.Item name="enabled" label="Actif">
          <Switch />
        </Form.Item>

        <Form.Item name="name" label="Nom" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name="code" label="Code">
          <Input />
        </Form.Item>

        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <Select options={resourceTypes} />
        </Form.Item>

        <Form.Item name="agency_id" label="Agence">
          <AgencyInput />
        </Form.Item>

        <Form.Item name="capacity" label="Capacité">
          <InputNumber suffix="jours" min={0} />
        </Form.Item>

        <Form.Item name="description" label="Notes">
          <Input.TextArea />
        </Form.Item>

      </fieldset >

      <fieldset className="box">
        <legend>Calendrier</legend>
        <Form.Item label="URL">
          <Space.Compact style={{ width: '100%' }}>
            <Input readOnly value={calendarUrl} />
            <Button type="primary" icon={<PaperClipOutlined />}
              onClick={() => { navigator.clipboard.writeText(calendarUrl) }} />
          </Space.Compact>
        </Form.Item>

        <Form.Item label="Voir les taches plannifiées">
          <Switch />
        </Form.Item>



      </fieldset>


      <ControlBar>
        <DeleteButton onDelete={onDelete} />
        <div>
          <Button type="primary" htmlType="submit">Enregistrer</Button>
        </div>
      </ControlBar>

    </Form >
  );
}
