import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  createSlot, getSlot, listSlots, removeSlot, updateSlot,
} from '../services/api/slots';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';
import { cache } from '../services/cache';
import {
  isRequired, validate, validateAll,
} from '../lib/validation';
import { useForm } from './form';
import { useResourcePrestations } from './resource-prestations';
import { indexBy } from '../lib/utils';
import { set } from 'date-fns';

const INVALIDATION_KEY = Symbol('slots');

const { invalidateCache, withCache } = cache();



export const slotStatuses = [
  {
    label: "Planifié",
    value: 'planned',
    color: '#06BEE1',
    movable: true,
  },
  {
    label: "Confirmé",
    value: 'confirmed',
    color: '#235789',
    movable: false,
  },
  {
    label: "Annulé",
    value: 'canceled',
    color: '#DE6E4B',
    movable: false,
  },
  {
    label: "Indisponible",
    value: 'unavailable',
    color: '#96ADC8',
    movable: false,
    hidden: true,
  },
  {
    label: "Terminé",
    value: 'done',
    color: '#52C41A',
    movable: true,
  }
];


export const slotCancelReasons = [
  {
    label: "Météo",
    value: 'weather',
  },
  {
    label: "Client",
    value: 'client',
  },
  {
    label: "Raison administrative",
    value: 'administrative',
  },
  {
    label: "Pas de personnel",
    value: 'staff',
  },
  {
    label: "Pas de matériel (commande non reçue, etc.)",
    value: 'material',
  },
  {
    label: "Problème d'équipement (voiture, outils, etc.)",
    value: 'equipment',
  },
  {
    label: "Autre",
    value: 'other',
  }
];


function getDefaults() {
  return {
    task_id: '',
    resource_id: '',
    start: '',
    end: '',
    status: 'planned',
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getSlot(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function useSlots({ task_id, resource_id, project_id } = {}) {
  const [slots, setSlots] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      try {
        // const terms = await withCache(async () => loaderWrap(listSlots()));
        const items = await loaderWrap(listSlots({ task_id, resource_id, project_id }));
        setSlots(items);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter, task_id, resource_id]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);

  const setUserSlot = useCallback((items) => {
    setSlots(items);
  }, [setSlots]);

  return [slots, refresh, setUserSlot];
}

export function useSlot(idOrSlot) {
  const [slot, setSlot] = useState({
    ...getDefaults(),
    ...(typeof idOrSlot === 'object' ? idOrSlot : {})
  });

  const id = typeof idOrSlot === 'string' ? idOrSlot : idOrSlot?.id;

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      if (idOrSlot && typeof idOrSlot === 'object') {
        // setSlot({
        //   ...getDefaults(),
        //   ...idOrSlot,
        // });
        return;
      }
      setSlot(await getItem(id));
    })();
  }, [idOrSlot, counter]);

  const saveSlot = useCallback(async (item) => {
    if (!item) {
      throw new Error('No slot');
    }

    // item = {
    //   ...item,
    //   start: new Date(item.start).toISOString(),
    //   end: new Date(item.end).toISOString(),
    // }

    let out;
    if (item.id) {
      out = await loaderWrap(updateSlot(item));
    } else {
      out = await loaderWrap(createSlot(item));
    }
    setSlot(out);
    invalidate(out.id);
    return out;
  }, [id, invalidate]);

  const deleteSlot = useCallback(async () => {
    await loaderWrap(removeSlot(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    slot,
    reloadSlot: () => invalidate(id),
    isNewSlot: !slot?.id,
    setSlot,
    saveSlot,
    deleteSlot,
  };
}

export function useSlotsCollection() {
  const [slots, refreshSlots, setSlots] = useSlots();

  return {
    slots,
    refreshSlots,

    addSlot: async (item) => {
      const slot = await loaderWrap(createSlot(item));
      setSlots([...slots, slot]);
    },
    updateSlot: async (item) => {
      const slot = await loaderWrap(updateSlot(item));
      setSlots(slots.map((s) => (s.id === slot.id ? slot : s)));
    },
    deleteSlot: async (id) => {
      await loaderWrap(removeSlot(id));
      setSlots(slots.filter((s) => s.id !== id));
    },
  };

}

export function slotValidator(values, name = undefined) {
  const rules = {
    start: [isRequired],
    end: [isRequired],
    task_id: [isRequired],
    resource_id: [isRequired],
    status: [isRequired],
  };
  if (name) {
    const err = validate(values[name], ...rules[name] || []);
    return { [name]: err || null };
  }
  return validateAll(values, rules);
}

export function useSlotForm(idOrSlot, onChange = undefined) {
  const { slot, saveSlot, deleteSlot } = useSlot(idOrSlot);

  const {
    register,
    handleSubmit,
    errors, isValid,
    setValues,
    values,
  } = useForm({
    values: {
      ...getDefaults(),
    },
    validator: slotValidator,
    onChange,
    // reValidateMode: "onChange"
  });

  useEffect(() => {
    if (slot) setValues(slot);
  }, [slot]);

  return {
    slot,
    register,
    errors,
    isValid,
    values,
    setValues,
    // rules,

    handleSubmit,
    saveSlot,
    deleteSlot,
  };
}




export function useSlotsConflictDetector(slots) {
  const [conflicts, setConflicts] = useState([]);

  useEffect(() => {
    const now = new Date();
    if (!slots) return;
    const conflicts = [];
    for (let i = 0; i < slots.length; i++) {
      const slot1 = slots[i];
      if (slot1.status === 'canceled') continue;
      if (slot1.end < now) continue;
      for (let j = 0; j < slots.length; j++) {
        const slot2 = slots[j];
        if (slot2.status === 'canceled') continue;
        if (slot2.end < now) continue;
        if (slot1.id === slot2.id) continue;
        if (slot1.resource_id === slot2.resource_id) {
          if (slot1.start <= slot2.end && slot2.start <= slot1.end) {
            if (!conflicts.find(({ slots: [s1, s2] }) => s1.id === slot2.id && s2.id === slot1.id)) {
              conflicts.push({
                id: `conflict-${slot1.id}-${slot2.id}`,
                type: 'conflict',
                level: 'error',
                slots: [slot1, slot2],
                message: `Conflict between ${slot1.id} and ${slot2.id}`,
                detail: {
                  slots: [slot1, slot2]
                }
              });
            }
          }
        }
      }
    }
    // console.log("conflicts", conflicts)
    setConflicts(conflicts);
  }, [slots]);

  return conflicts;
}

export function useSlotsBadResourceDetector({ slots, tasks, resources, projects }) {
  const [respres] = useResourcePrestations();
  return useMemo(() => {
    if (!slots || !tasks || !resources || !projects) return [];
    const tasksById = indexBy(tasks, "id");
    const resourcesById = indexBy(resources, "id");
    const projectsById = indexBy(projects, "id");
    const now = new Date();
    // console.log(respres)
    const errors = [];
    for (const slot of slots) {
      if (slot.status === 'canceled') continue;
      if (slot.end < now) continue;
      const task = tasksById[slot.task_id];
      const resource = resourcesById[slot.resource_id];
      if (!task || !resource) continue;
      const agencyId = projectsById[task.project_id]?.agency_id
      const rp = respres.find((r) => r.resource_id === resource.id && r.prestation_id === task.prestation_id);
      // console.log(resource.id, task.prestation_id, rp)
      const { isValid, isOutOfAgency, isNotFound, isUnderLevel } = getResourcePrestationScore({
        level: rp?.level,
        agencyId: resource?.agency_id,
      }, {
        level: task.expected_level,
        agencyId: agencyId,
      });

      if (!isValid) {
        errors.push({
          id: `bad-resource-${slot.id}`,
          type: 'bad-resource',
          level: 'warning',
          message: `Resource ${resource.name} is not valid for task ${task.name}`,
          slots: [slot],
          detail: {
            resource,
            task,
            isNotFound,
            isOutOfAgency,
            isUnderLevel
          }
        });
      }
    }

    return errors;
  }, [slots, tasks, resources, projects, respres]);
}

export function useSlotsValidator(slots, { tasks = [], resources = [], projects = [] } = {}, events = []) {

  // const visibleSlots = slots.filter((s) => !!idx[s.id])
  const visibleSlots = useMemo(() => {
    const idx = indexBy(events, "id")
    const out = slots.filter((s) => !!idx[s.id])
    return out
  }, [slots, events])
  const conflicts = useSlotsConflictDetector(visibleSlots);
  const badPres = useSlotsBadResourceDetector({ slots: visibleSlots, tasks, resources, projects });

  return useMemo(() => {
    const errors = []

    errors.push(...conflicts)
    errors.push(...badPres)

    return errors;
  }, [conflicts, badPres]);

}


export function getResourcePrestationScore(given, expected) {
  // console.log(given, expected)
  const { level: givenLevel, agencyId: givenAgencyId } = given
  const { agencyId: expectedAgencyId, level: expectedLevel } = expected
  let score = 0;

  const isUnderLevel = givenLevel < expectedLevel
  const isNotFound = givenLevel === undefined
  const isOutOfAgency = expectedAgencyId && givenAgencyId !== expectedAgencyId
  const isValid = !isUnderLevel && !isOutOfAgency && !isNotFound


  if (!isOutOfAgency) {
    score += 10
  }

  score += givenLevel || 0

  return {
    isUnderLevel,
    isNotFound,
    isOutOfAgency,
    isValid,
    score
  }
  // return useMemo(() => {
  //   const idx = indexBy(resPrestations, "resource_id")
  //   const items = resources
  //     // .filter((r) => {
  //     //   return !busyResources.includes(r.id) && idx[r.id]
  //     // })
  //     .map((r) => {

  //     })
  //   return items || []
  // }, [resources, resPrestations])
}