import React, { useCallback, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ID } from '../../components/id/id';
import { taskStatuses, useTasks } from '../../hooks/tasks';
import { useSearcher } from '../../hooks/search';
import TaskStatusLabel from '../../components/tasks/TaskStatusLabel';
import { useProjects } from '../../hooks/projects';
import { Button, Flex, Input, Modal, Progress, Rate, Space, Table, Upload, message } from 'antd';
import { ImportOutlined, LoadingOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { indexBy } from '../../lib/utils';
import TaskTypeLabel from '../../components/tasks/TaskTypeLabel';
import Search from '../../components/search/Search';
import { useAgencies } from '../../hooks/agencies';
import { usePrestations } from '../../hooks/prestations';
import { createImportTask, startImportTask } from '../../services/api/tasks';
import { uploadData } from 'aws-amplify/storage'



async function uploadFile(
  file,
  presignedUploadUrl,
  onProgress,
) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (e) => {
      if (e.lengthComputable) {
        const pct = e.loaded / e.total;
        onProgress(pct * 100);
      }
    });
    xhr.upload.addEventListener("error", (e) => {
      reject(new Error("Upload failed: " + e.toString()));
    });
    xhr.upload.addEventListener("abort", (e) => {
      reject(new Error("Upload aborted: " + e.toString()));
    });
    xhr.addEventListener("load", (e) => {
      if (xhr.status === 200) {
        resolve();
      } else {
        reject(new Error("Upload failed " + xhr.status));
      }
    });
    xhr.open("PUT", presignedUploadUrl, true);
    xhr.setRequestHeader('content-type', 'text/csv')
    console.log(file)
    try {
      xhr.send(file);
    } catch (e) {
      reject(new Error("Upload failed: " + e.toString()));
    }
  });
}

// async function uploadFile2(file, url, onProgress) {
//   const res = await window.fetch(url, {
//     method: "PUT",
//     body: file,
//   });

//   const location = res.headers.get("Location"); // get the final url of our uploaded image
//   return decodeURIComponent(location);
// }

export default function TasksList() {
  const [tasks, refreshTasks] = useTasks();
  const [projects, refreshProjects] = useProjects();
  const [agencies, refreshAgencies] = useAgencies();
  const [prestations, refreshPrestations] = usePrestations();

  const [uploadID, setUploadID] = React.useState(null);

  const [filters, setFilters] = React.useState({});

  const displayedTasks = useMemo(() => {
    return tasks.map(t => {
      const project = projects.find(p => p.id === t.project_id)
      return {
        ...t,
        project_name: project ? project.name : 'Inconnu',
        agency_id: project ? project.agency_id : null
      }
    })
  }, [tasks, projects])

  // const { filteredList, search, setSearch } = useSearcher(
  //   displayedTasks,
  //   useCallback((o, search) => `${o.name} ${o.project_name}`.toLowerCase().includes(search.toLowerCase()), []),
  // );

  const filteredList = useMemo(() => {
    return displayedTasks.filter(task => {
      if (filters.tasks && !filters.tasks.includes(task.id)) return false
      if (filters.projects && !filters.projects.includes(task.project_id)) return false
      if (filters.agencies && !filters.agencies.includes(task.agency_id)) return false
      if (filters.prestations && !filters.prestations.includes(task.prestation_id)) return false
      return true
    })
  }, [displayedTasks, filters])


  const treeList = useMemo(() => {
    const list = filteredList.map(item => ({ ...item }));
    const idx = indexBy(list, "id")
    const out = []
    list.forEach((item) => {
      console.log(item.parent_task_id)
      if (item.parent_task_id) {
        const parent = idx[item.parent_task_id]
        if (parent) {
          if (!parent.children) {
            parent.children = []
          }
          parent.children.push(item)
          return
        }
      }
      out.push(item)
    });
    return out;
  }, [filteredList]);

  const navigate = useNavigate();
  const open = (id) => {
    navigate(`./${id}`);
  };

  const customUploadRequest = async ({ file, onProgress, onSuccess, onError }) => {
    console.log(file)
    const { id, url } = await createImportTask()
    setUploadID(id);

    try {
      message.info({
        key: 'upload_progress',
        content: `Upload en cours`,
      })
      await uploadFile(file, url, (pct) => {
        message.info({
          key: 'upload_progress',
          content: `Upload en cours: ${Math.round(pct)}%`,
        })
        onProgress({ percent: pct }, file)
      })
      message.info({
        key: 'upload_progress',
        content: `Importation en cours`,
      })
      const count = await startImportTask(id)
      message.success({
        key: 'upload_progress',
        content: `Importation terminée: ${count} tâches importées`,
      })
      onSuccess()
    } catch (e) {
      message.error(`Erreur: ${e}`)
      onError(e)
    }
    setUploadID()

    refreshTasks()
    refreshAgencies()
    refreshProjects()
    refreshPrestations()

    return url;
  }




  return (
    <>
      <div className="box">
        <Flex gap="small" align='center' >
          {/* <Input size="large" placeholder="Rechercher une tâche..."
            prefix={<SearchOutlined />}
            onChange={(e) => setSearch(e.target.value)}
          /> */}
          <Search
            projects={projects}
            agencies={agencies}
            prestations={prestations}
            tasks={tasks}
            onChange={values => setFilters(values)} />

          {/* <Button type="primary" icon={<UploadOutlined />} onClick={() => startImport()}  >Importer</Button> */}
          <Upload customRequest={customUploadRequest} showUploadList={false} accept='text/csv' >
            <Button icon={uploadID ? <LoadingOutlined /> : <UploadOutlined />} >Importer</Button>
          </Upload>
          <Button type="primary" onClick={() => open("*")} >Créer une tâche</Button>
        </Flex>
      </div >
      <div className="box-paddingless">

        <Table
          dataSource={treeList}
          expandable={{ defaultExpandAllRows: true }}
          rowKey={(record) => record.id}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => open(record.id), // click row
            };
          }}
          columns={[
            {
              title: 'Nom',
              dataIndex: 'name',
              key: 'name',
              sorter: (a, b) => String(a.name).localeCompare(b.name),
            },
            {
              title: 'Affaire',
              dataIndex: 'project_name',
              key: 'project_name',
              sorter: (a, b) => String(a.project_name).localeCompare(b.project_name),
            },
            // {
            //   title: 'Code Client',
            //   dataIndex: 'customer_code',
            //   key: 'customer_code',
            //   sorter: (a, b) => String(a.customer_code).localeCompare(b.customer_code),
            // },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              render: (text, record) => {
                let progress = 0;
                if (record.status === 'inplanning') {
                  progress = Math.round(record.planned_percent)
                } else if (record.status === 'planned') {
                  progress = Math.round(record.complete_percent)
                }
                return <Space direction='vertical'>
                  <TaskStatusLabel value={record.status} progress={progress != null && record.status === 'inplanning'
                    ? progress : undefined} />
                </Space>
              },
              filters: taskStatuses.map(({ label, value }) => ({ text: label, value })),
              onFilter: (value, record) => record.status === value,
            },
            {
              title: 'Type',
              dataIndex: 'type',
              key: 'type',
              render: (text, record) => <TaskTypeLabel value={record.type} />,
              filters: taskStatuses.map(({ label, value }) => ({ text: label, value })),
              onFilter: (value, record) => record.type === value,
            },
            {
              title: 'Compétences',
              dataIndex: 'expected_level',
              key: 'expected_level',
              sorter: (a, b) => a.expected_level - b.expected_level,
              render: (text, record) => {
                return <Rate value={Math.ceil(record.expected_level * 3 / 5)} count={3} />
              },
            }
          ]}
        />

      </div>
      {/* <Modal title="Importer des tâches"
        centered
        open={!!uploadID}
        onOk={() => { }} onCancel={() => { }}>
        <Space direction="vertical">
          <Upload onChange={onUploadChange } action={}>
            <Button icon={<UploadOutlined />} >Importer un fichier</Button>
          </Upload>
        </Space>
      </Modal> */}
    </>
  );
}
