import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/projects');
const { invalidateCache, withCache } = cache();

function mapFromProjectModel(input) {
  return { ...input };;
}

function mapToProjectModel(input) {
  return { ...input };
}

export async function listProjects() {
  const items = await withCache(async () => {
    const { items } = await api.get();
    return items;
  })
  return items ? items.map(mapToProjectModel) : [];
}

export async function getProject(id) {
  const item = await withCache(async () => {
    const { item } = await api.get(id);
    if (!item) {
      throw new Error('Project not found');
    }
    return item;
  }, id)

  const project = mapToProjectModel(item);
  return project;
}

export async function createProject(project) {
  const { item } = await api.post('', { item: mapFromProjectModel(project) });
  if (item) {
    invalidateCache(item.id)
    return mapToProjectModel(item)
  }
  return undefined;
}

export async function updateProject(project) {
  const res = await api.put('', { item: mapFromProjectModel(project) });
  invalidateCache(project.id)
  return res ? project : undefined;
}

export async function removeProject(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  return !!res;
}
