
import { useNavigate, useParams } from 'react-router-dom';
import DeleteButton from '../../components/deletebutton/DeleteButton';
import { useAgency, useAgencyForm } from '../../hooks/agencies';
import { ControlBar } from '../../components/controlbar/ControlBar';
import { DatePicker, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import dayjs from 'dayjs';

// import { SubjectTimeline } from '../../components/dataviz/SubjectTimeline';
// import { ControlBar } from '../../components/controlbar/ControlBar';

export default function AgencyForm() {
  const navigate = useNavigate();
  const { agencyId } = useParams();
  const [form] = Form.useForm();
  const [messageApi, messageContextHolder] = message.useMessage();

  const {
    agency, saveAgency, deleteAgency,
  } = useAgency(agencyId || '');


  useEffect(() => {
    if (agency) {
      form.setFieldsValue({
        ...agency,
      });
    }
  }, [form, agency]);


  if (!agency) {
    return <div>Not found</div>;
  }



  const onFinish = (values) => {
    (async () => {
      try {
        const item = await saveAgency({
          ...agency,
          ...values,
        });
        messageApi.success('Enregistré');
        if (item) {
          navigate('/agencies');
        }
      } catch (err) {
        messageApi.error(`Erreur: ${err}`);
      }
    })();
  };

  const onDelete = () => {
    if (agencyId) {
      (async () => {
        await deleteAgency();
        messageApi.success('Supprimé');
        setTimeout(() => {
          navigate('/agencies');
        }, 1000);
      })();
    }
  };


  return (
    <Form form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ flex: 1 }}
      labelWrap
      onFinish={onFinish}
    >
      {messageContextHolder}
      <fieldset className="box">
        <legend>Informations</legend>
        <Form.Item name="name" label="Nom" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="code" label="Code" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="phone" label="Téléphone">
          <Input />
        </Form.Item>
        <Form.Item name="address" label="Adresse">
          <Input />
        </Form.Item>
      </fieldset>

      <ControlBar>
        <DeleteButton onDelete={onDelete} />
        <div>
          <button type="submit" className="btn btn-primary" >Enregistrer</button>
        </div>
      </ControlBar>

    </Form>
  );
}
