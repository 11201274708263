
import { useMemo } from "react";
import { usePrestations } from "../../hooks/prestations";
import PrestationIcon from "./PrestationIcon";
import { Select } from "antd";

export function PrestationInput({ value, name, placeholder, valueFilter, onChange }) {
  const [items] = usePrestations()


  const filteredItems = useMemo(() => {
    if (valueFilter) {
      return items.filter(valueFilter)
    }
    return items || []
  }, [items, valueFilter])

  return <Select
    showSearch
    placeholder={placeholder || 'Sélectionner une ressource'}
    optionFilterProp="children"
    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
    filterSort={(optionA, optionB) =>
      (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
    }
    fieldNames={{
      label: "name",
      value: "id",
    }}
    value={value}
    onChange={onChange}
    options={filteredItems}
  />
}