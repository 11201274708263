import React from 'react';



import Home from './pages/Home';
import Overview from './pages/overview/Overview';
import Projects from './pages/projects/Projects';
import Planning from './pages/planning/Planning';
import Resources from './pages/resources/Resources';
import Prestations from './pages/prestations/Prestations';
import ProjectsList from './pages/projects/ProjectsList';
import ProjectForm from './pages/projects/ProjectForm';
import { useProject } from './hooks/projects';
import PrestationsList from './pages/prestations/PrestationsList';
import PrestationForm from './pages/prestations/PrestationForm';
import { usePrestation } from './hooks/prestations';
import ResourcesList from './pages/resources/ResourcesList';
import ResourceForm from './pages/resources/ResourceForm';
import { useResource } from './hooks/resources';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import TasksList from './pages/tasks/TasksList';
import TaskForm from './pages/tasks/TaskForm';
import { useTask } from './hooks/tasks';
import Tasks from './pages/tasks/Tasks';
import ResourceItem from './pages/resources/ResourceItem';
import ResourcePrestations from './pages/resources/ResourcePrestations';
import TaskResources from './pages/tasks/TaskResources';
import TaskPlanning from './pages/tasks/TaskPlanning';
import TaskDocuments from './pages/tasks/TaskDocuments';
import TaskItem from './pages/tasks/TaskItem';
import Agencies from './pages/agencies/Agencies';
import AgenciesList from './pages/agencies/AgenciesList';
import AgencyForm from './pages/agencies/AgencyForm';
import { useAgency } from './hooks/agencies';
import PlanningView from './pages/planning/PlanningView';

function Crumb({ label, isNew, newLabel }) {
  return <b>{isNew ? (newLabel || 'Nouveau') : label || ''}</b>;
}

function CrumbAgency({ match }) {
  const { agency, isNewAgency } = useAgency(match.params.agencyId);
  return <Crumb label={agency?.name} isNew={isNewAgency} />;
}

function CrumbProject({ match }) {
  const { project, isNewProject } = useProject(match.params.projectId);
  return <Crumb label={project?.name} isNew={isNewProject} />;
}

function CrumbPrestation({ match }) {
  const { prestation, isNewPrestation } = usePrestation(match.params.prestationId);
  return <Crumb label={prestation?.name} isNew={isNewPrestation} />;
}

function CrumbResource({ match }) {
  const { resource, isNewResource } = useResource(match.params.resourceId);
  return <Crumb label={resource?.name} isNew={isNewResource} />;
}

function CrumbTask({ match }) {
  const { task, isNewTask } = useTask(match.params.taskId);
  const [p] = useSearchParams()

  return <Crumb label={task?.name} isNew={isNewTask} newLabel={p.get("type") === 'after-sales' ? 'Nouveau SAV' : null} />;
}

export const routes = [
  {
    path: '/',
    key: 'home',
    // breadcrumb: ">",
    // component: <Overview />,
    component: <Navigate to="/planning/view" replace />
  },
  {
    path: "/planning",
    key: "planning",
    component: <Planning />,
    breadcrumb: "Planning",
    children: [
      {
        path: "",
        key: "planningRoot",
        breadcrumb: "Planning",
        component: <Navigate to="view" replace />
      },
      {
        path: "view",
        key: "planningProjects",
        // breadcrumb: "Planning",
        component: <PlanningView />,
      },
      // {
      //   path: "projects",
      //   key: "planningProjects",
      //   breadcrumb: "Affaires",
      //   component: <PlanningProjects />,
      // },
      // {
      //   path: "resources",
      //   key: "planningResources",
      //   breadcrumb: "Resources",
      //   component: <PlanningResources />,
      // },
    ],

  },
  {
    path: "/agencies",
    key: "agencies",
    component: <Agencies />,
    children: [
      {
        path: "",
        key: "agenciesList",
        breadcrumb: "Agences",
        component: <AgenciesList />,
      },
      {
        path: ":agencyId",
        key: "agencyForm",
        breadcrumb: CrumbAgency,
        component: <AgencyForm />,
      },
    ]
  },

  {
    path: "/projects",
    key: "projects",
    component: <Projects />,
    children: [
      {
        path: "",
        key: "projectsList",
        breadcrumb: "Affaires",
        component: <ProjectsList />,
      },
      {
        path: ":projectId",
        key: "projectForm",
        breadcrumb: CrumbProject,
        component: <ProjectForm />,
      },
    ]
  },

  {
    path: "/tasks",
    key: "tasks",
    component: <Tasks />,
    children: [
      {
        path: "",
        key: "tasksList",
        breadcrumb: "Tâches",
        component: <TasksList />,
      },
      {
        path: ":taskId",
        key: "taskForm",
        breadcrumb: CrumbTask,
        component: <TaskItem />,
        children: [
          {
            path: "",
            key: "resourceRoot",
            // breadcrumb: "Planning",
            component: <Navigate to="details" replace />
          },
          {
            path: "details",
            key: "taskForm",
            breadcrumb: CrumbTask,
            component: <TaskForm />,
          },
          {
            path: "resources",
            key: "taskResources",
            breadcrumb: CrumbTask,
            component: <TaskResources />,
          },
          {
            path: "planning",
            key: "taskPlanning",
            breadcrumb: CrumbTask,
            component: <TaskPlanning />,
          },
          {
            path: "documents",
            key: "taskDocuments",
            breadcrumb: CrumbTask,
            component: <TaskDocuments />,
          },
        ]
      },
    ]
  },

  {
    path: "/resources",
    key: "resources",
    component: <Resources />,
    children: [
      {
        path: "",
        key: "resourcesList",
        breadcrumb: "Ressources",
        component: <ResourcesList />,
      },
      {
        path: ":resourceId",
        key: "resourceItem",
        component: <ResourceItem />,
        breadcrumb: CrumbResource,
        children: [
          {
            path: "",
            key: "resourceRoot",
            // breadcrumb: "Planning",
            component: <Navigate to="details" replace />
          },
          {
            path: "details",
            key: "resourceForm",
            breadcrumb: CrumbResource,
            component: <ResourceForm />,
          },
          {
            path: "prestations",
            key: "resourcePrestation",
            breadcrumb: CrumbResource,
            component: <ResourcePrestations />,
          },
        ]
      },


      // {
      //   path: ":resourceId",
      //   key: "resourceForm",
      //   breadcrumb: CrumbResource,
      //   component: <ResourceForm />,
      // },
    ]
  },

  {
    path: "/prestations",
    key: "prestations",
    component: <Prestations />,
    children: [
      {
        path: "",
        key: "prestationsList",
        breadcrumb: "Prestations",
        component: <PrestationsList />,
      },
      {
        path: ":prestationId",
        key: "prestationForm",
        breadcrumb: CrumbPrestation,
        component: <PrestationForm />,
      },
    ]
  },


];
