import React, { Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import AppSidebar from './components/app/AppSidebar';

import { Breadcrumbs } from './components/breadcrumb/Breadcrumbs';
import { routes } from './routes';
import AppTop from './components/app/AppTop';

function renderRoute(r, depth = 0) {
  let el = r.component; // <r.component />

  if (depth === 0) {
    el = (
      <div className="app-grid">
        <AppSidebar />
        <main role="main" className="app-main" id="app-main">
          <AppTop />
          {el}
        </main>
      </div>
    );
  }

  // if (!r.anonymous) {
  //   el = <SecuredRoute>{el}</SecuredRoute>
  // }
  return (
    <Route
      key={r.key}
      path={r.path}
      element={<Suspense>{el}</Suspense>}
    >
      {(r.children || []).map((r) => renderRoute(r, depth + 1))}
    </Route>
  );
}

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Suspense fallback={<p>Loading...</p>}>
        <Routes>
          {
            routes.map((r) => renderRoute(r))
          }
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

// export  function AppRoute() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<Home />}></Route>
//       </Routes>
//     </BrowserRouter>
//   )
// }
