import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import Style from './ControlBar.module.scss';

export function ControlBar({ children }) {
  const ref = useRef();

  useEffect(
    () => {
      const observer = new IntersectionObserver(
        ([e]) => {
          e.target.classList.toggle(Style.sticked, e.intersectionRatio < 1);
        },
        { threshold: [1] },
      );
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    },
    [ref.current],
  );

  return (
    <div ref={ref} className={clsx(Style.controlbar, 'box')}>
      {children}
    </div>
  );
}
