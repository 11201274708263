
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useProjects } from "../../hooks/projects";
import { useTasks } from "../../hooks/tasks";
import { slotCancelReasons, slotStatuses, useSlot, useSlots, useSlotsCollection, useSlotsValidator } from "../../hooks/slots";
import { usePrestations } from "../../hooks/prestations";
import { indexBy } from "../../lib/utils";
import Scheduler, { DATETIME_FORMAT } from '../../components/scheduler/Scheduler';
import SchedulerData from '../../components/scheduler/SchedulerData';
import { DATE_FORMAT } from '../../components/scheduler/Scheduler';
import ViewTypes from '../../components/scheduler/ViewType';
import PrestationIcon from "../../components/prestations/PrestationIcon";
import ResourceIcon from "../../components/resources/ResourceIcon";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import '../../components/scheduler/css/style.css'

import dayjs from 'dayjs';

import { Alert, Badge, Button, DatePicker, Divider, Flex, Form, Input, List, Modal, Popover, Radio, Rate, Row, Space, message } from "antd";


import ResourceSlotEventItem from "../../components/planning/ResourceSlotEventItem";


import ResourceSlotMilestoneItem from "../../components/planning/ResourceSlotMilestoneItem";
import { PlanningErrorItem } from "../../components/planning/PlanningErrorItem";
import ResourcePopover from "../../components/planning/ResourcePopover";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import PlanningSlotForm from "../../components/planning/PlanningSlotForm";
import PlanningSlotHeaderItemRenderer from "../../components/planning/PlanningSlotHeaderItemRenderer";
import { roundToEndWorkTimeIfNeeded, roundToStartWorkTimeIfNeeded } from "../../components/planning/utils";
import { useByProjects, useByResources, usePlanningDatastore } from "./data";
import TaskSlotEventItem from "../../components/planning/TaskSlotEventItem";

import Search from "../../components/search/Search";
import SlotCancelReasonInput from "../../components/slots/SlotCancelReasonInput";






const IsolateRenderer = forwardRef(({ item, ...props }, ref) => {
  const [newProps, setNewProps] = useState({})
  ref.current = {
    setProps: (v) => {
      setNewProps(v)
    }
  }

  return item({ ...props, ...newProps })
})



export default function PlanningView() {

  const [filters, setFilters] = useState({})

  const refDatePicker = useRef();
  const [state, setState] = useState({
    data: new SchedulerData(new dayjs().format(DATE_FORMAT), ViewTypes.Year, false, false, {
      // startResizable: false,
      // endResizable: false,
      movable: true,
      creatable: true,
      crossResourceMove: false,
      // checkConflict: false,
      schedulerMaxHeight: "700px",
      besidesWidth: 0,
      eventItemHeight: 25,
      eventItemLineHeight: 30,
      headerEnabled: false,


      defaultEventBgColor: '#80C5F6',
      selectedAreaColor: '#7EC2F3',
      nonWorkingTimeHeadColor: '#999999',
      nonWorkingTimeHeadBgColor: '#dfdfdf',
      nonWorkingTimeBodyBgColor: '#f3f3f3',
      summaryColor: '#666',
      groupOnlySlotColor: '#F8F8F8',

      resourceName: "Affaires",
      taskName: "Tâches",
      nonAgendaDayCellHeaderFormat: 'HH',
      addMorePopoverHeaderFormat: 'D MMM, YYYY dddd',
      eventItemPopoverDateFormat: 'D MMM',
      nonAgendaOtherCellHeaderFormat: 'ddd D/M',
      responsiveByParent: true,

      dayResourceTableWidth: 300,
      weekResourceTableWidth: 300,
      monthResourceTableWidth: 300,
      quarterResourceTableWidth: 300,
      yearResourceTableWidth: 300,
      customResourceTableWidth: 300,

      scrollToSpecialDayjsEnabled: true,

    }, {
      getScrollSpecialDayjsFunc: (schedulerData, startDayjs, endDayjs) => {
        if (schedulerData.pendingAlignDate) {
          return schedulerData.pendingAlignDate.add(-2, "day")
        }
        return null;
      }
    })
  })

  const refBox = useRef()
  const [form] = Form.useForm();

  const datastore = usePlanningDatastore();
  const {
    tasks,
    users,
    projects,
    prestations,
    agencies,
    slots,

    deleteSlot,
    updateSlot,
    addSlot,
    // saveSlot,
  } = datastore;



  const [mode, setMode] = useState('projects')
  // const [sums, setSums] = useState({})
  const [editedSlot, setEditedSlot] = useState(null)
  const [savingSlot, setSavingSlot] = useState(false);
  const [deletingSlot, setDeletingSlot] = useState(false);
  const [refreshState, setRefresh] = useState();

  const [cancelingSlot, setCancelingSlot] = useState(null);

  const byProjects = useByProjects(datastore, filters)
  const byResources = useByResources(datastore, filters)

  const { data } = state;

  const refreshDisplay = useCallback(() => {
    setRefresh({})
  }, []);

  // useEffect(() => {
  //   console.log('GET localstorage', localStorage.getItem('planning.filters'))

  // }, [])

  const localFilters = useMemo(() => {
    if (localStorage.getItem('planning.filters')) {
      return JSON.parse(localStorage.getItem('planning.filters'))
    }
    return {}
  }, [])

  useEffect(() => {
    console.log('GET localstorage', localFilters)
    setFilters(localFilters || {})
  }, [localFilters])



  const { resources, events, sums } = useMemo(() => {
    switch (mode) {
      case 'projects':
        return byProjects
      case 'resources':
        return byResources
      default:
        return {
          resources: [],
          events: [],
          sums: {},
        }
    }
  }, [mode, byProjects, byResources])


  const tasksIdx = useMemo(() => {
    return indexBy(tasks || [], "id");
  }, [tasks])

  const usersIdx = useMemo(() => {
    return indexBy(users || [], "id");
  }, [users])

  const projectsIdx = useMemo(() => {
    return indexBy(projects || [], "id");
  }, [projects])

  const prestationsIdx = useMemo(() => {
    return indexBy(prestations || [], "id");
  }, [prestations])

  const slotsIdx = useMemo(() => {
    return indexBy(slots || [], "id");
  }, [slots])

  const errors = useSlotsValidator(slots, { tasks, resources: users, projects }, events)

  const errorsIdx = useMemo(() => {
    const idx = {};
    errors.forEach(error => {
      error.slots.forEach(({ id }) => {
        idx[id] = idx[id] || [];
        idx[id].push(error);
      })
    })
    return idx;
  }, [errors])

  const errorsByDates = useMemo(() => {
    const idx = {};
    errors.forEach(error => {
      error.slots.forEach(({ start, end }) => {
        start = new dayjs(start)
        end = new dayjs(end)
        for (let date = start; date < end; date = date.add(1, 'day')) {
          const key = date.format(DATE_FORMAT);

          idx[key] = idx[key] || [];
          idx[key].push(error);
        }
      }
      )
    })
    return idx;
  }, [errors])


  useEffect(() => {
    data.setSchedulerLocale('fr');
    data.setCalendarPopoverLocale('fr_FR');
    switch (mode) {
      case 'projects':
        data.config.resourceName = "Affaires";
        data.config.taskName = "Tâches";
        break;
      case 'resources':
        data.config.resourceName = "Ressources";
        data.config.taskName = "Tâches";
        break;
      default:
    }
    data.setResources(resources);
    data.setEvents(events);

    refreshDisplay()
  }, [mode, events, resources])


  useEffect(() => {
    data.setEvents(events);
    setState({ data });
  }, [data, refreshState, events])

  useEffect(() => {
    onDateChange(new dayjs())
  }, [])

  const editSlot = (slot) => {
    form.resetFields();
    form.setFieldsValue({
      ...slot,
      dates: [slot.start, slot.end]
    })

    setEditedSlot(slot)
  }


  const onDateChange = (date) => {
    if (!date) return;
    // console.log('setPendingAlignDate', date)
    data.pendingAlignDate = date;
    data.setDate(date.format(DATE_FORMAT));
    data.setScrollToSpecialDayjs(true);
    refreshDisplay()
  }

  const updateEvent = useCallback(async (event, start, end) => {
    console.log("updateEvent", event, start, end)

    await updateSlot({
      ...event.slot,
      start: roundToStartWorkTimeIfNeeded(start),
      end: roundToEndWorkTimeIfNeeded(end),
    })

  }, [updateSlot])



  const onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    refreshDisplay()
  }


  const onEventClicked = (schedulerData, event) => {
    const { slot } = event
    if (!slot) return;
    if (mode === 'resources') return;

    editSlot({
      id: slot.id,
      status: slot.status,
      start: roundToStartWorkTimeIfNeeded(slot.start),
      end: roundToEndWorkTimeIfNeeded(slot.end),
      task_id: slot.task_id,
      resource_id: slot.resource_id,
    })

    return false
  }

  const onUpdateEventStart = (schedulerData, event, newStart) => {
    if (mode === 'resources') return;
    console.log("onUpdateEventStart", event, newStart)
    updateEvent(event, newStart, event.end)
  }

  const onUpdateEventEnd = (schedulerData, event, newEnd) => {
    if (mode === 'resources') return;
    console.log("onUpdateEventEnd", event, newEnd)
    updateEvent(event, event.start, newEnd)
  }

  const onMoveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if (mode === 'resources') return;
    console.log("onMoveEvent", event, slotId, slotName, start, end)
    updateEvent(event, start, end)
  }

  const onNewEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    if (mode === 'resources') return;
    console.log("onNewEvent", slotId, slotName, start, end, type, item)

    editSlot({
      start: roundToStartWorkTimeIfNeeded(start),
      end: roundToEndWorkTimeIfNeeded(end),
      status: 'planned',
      task_id: slotId,
      resource_id: null,
    })

    // refreshDisplay()
  }


  const onViewportChange = (schedulerData, schedulerContent, viewport) => {
    if (schedulerData.pendingAlignDate) {
      console.log('resetPendingAlignDate', schedulerData.pendingAlignDate)
      schedulerData.pendingAlignDate = null;
    }
    if (schedulerData.viewport?.key !== viewport.key) {
      schedulerData.viewport = viewport
      if (refDatePicker.current)
        refDatePicker.current.setProps({ value: new dayjs(viewport.middle) });
    }
  }

  const onEditModalOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        setSavingSlot(true)

        let status = editedSlot.status
        if (editedSlot.task_id === "unavailable") {
          status = 'unavailable'
        }

        const slot = {
          ...editedSlot,
          ...values,
          status: status,
          // task_id: editedSlot.task_id || 'unavailable',
          start: values.dates[0].toISOString(),
          end: values.dates[1].toISOString()
        }

        if (slot.id) {
          await updateSlot(slot)
        } else {
          await addSlot(slot);
        }
        setSavingSlot(false)
        setEditedSlot(null)
        message.success('Enregistré');
        // onCreate(values);
      })
      .catch((info) => {
        message.error('Erreur: ' + info);
        console.log('Validate Failed:', info);
      });
  }

  const onNextClick = (vp) => {
    const { middle, interval } = data.viewport
    onDateChange(new dayjs(middle).add(interval, 'days'))
  }

  const onPrevClick = () => {
    const { middle, interval } = data.viewport
    // console.log('onPrevClick', start, interval)
    onDateChange(new dayjs(middle).add(-interval, 'days'))
  }

  const onSetStatus = (slotId, status, cancelReason = undefined) => {
    console.log('onSetStatus', slotId, status);
    (async () => {
      await updateSlot({
        ...slotsIdx[slotId],
        cancel_reason: cancelReason,
        status,
      })
      refreshDisplay()
    })()
  }

  const onSearchChange = (values) => {
    console.log('onFilterChange', values)
    localStorage.setItem('planning.filters', JSON.stringify(values))
    setFilters(values)
  }


  const onDeleteSlot = async () => {
    setDeletingSlot(true)
    await deleteSlot(editedSlot.id)
    setDeletingSlot(false)
    setEditedSlot(null)
  }


  return (<>
    <div className="box">
      <Flex align={"center"} justify={"end"} gap={"small"}>

        <Radio.Group value={mode} onChange={(e) => setMode(e.target.value)}>
          <Radio.Button value="projects">Affaires</Radio.Button>
          <Radio.Button value="resources">Ressources</Radio.Button>
        </Radio.Group>
        <div style={{ flex: 1 }}>
          <Search
            projects={projects}
            agencies={agencies}
            resources={users}
            tasks={tasks}
            prestations={prestations}
            values={filters}
            onChange={values => onSearchChange(values)} />
        </div>

        <div>
          <Space.Compact block>
            <Button
              onClick={() => onPrevClick()}
              // size="small"
              icon={<LeftOutlined />}
            />
            <IsolateRenderer
              ref={refDatePicker}
              item={({ value }) => <DatePicker
                style={{ fontWeight: 'bold' }}
                onChange={onDateChange}
                format={'DD/MM/YYYY'}
                // size="large"
                allowClear={false}
                // variant="borderless"
                value={value}
              />}
            />
            <Button
              onClick={() => onNextClick()}
              // size="small"
              icon={<RightOutlined />}
            />
          </Space.Compact>
        </div>

        <Popover title="Problèmes"
          content={<div style={{ maxWidth: 400, maxHeight: 500, overflow: 'auto' }}>
            <Space direction="vertical" size="small">
              {errors.map((error, i) => <PlanningErrorItem key={i} error={error} users={users} tasks={tasks} />)}
            </Space>
          </div>} >
          <Badge count={errors?.length} />
        </Popover>

      </Flex>
    </div>
    <div className="box" style={{ overflow: "hidden", padding: 0 }} >
      <div ref={refBox}>

        <DndProvider backend={HTML5Backend}>
          <Scheduler
            parentRef={refBox}
            schedulerData={data}
            // prevClick={prevClick}
            // nextClick={nextClick}
            // onSelectDate={onSelectDate}
            onViewChange={onViewChange}
            eventItemClick={onEventClicked}

            moveEvent={onMoveEvent}
            updateEventStart={onUpdateEventStart}
            updateEventEnd={onUpdateEventEnd}
            newEvent={onNewEvent}

            onViewportChange={onViewportChange}
            nonAgendaCellHeaderTemplateResolver={(schedulerData, item, formattedDateItems, style) => {
              let datetime = schedulerData.localeDayjs(item.time);
              let isCurrentDate = false;

              if (schedulerData.viewType === ViewTypes.Day) {
                isCurrentDate = datetime.isSame(new Date(), 'hour');
              }
              else {
                isCurrentDate = datetime.isSame(new Date(), 'day');
              }

              if (isCurrentDate) {
                style.backgroundColor = '#118dea';
                style.color = 'white';
              }

              const errors = errorsByDates[datetime.format(DATE_FORMAT)] || []
              if (errors.length > 0) {
                const hasError = (errors.findIndex(e => e.level === 'error') !== -1)
                style.backgroundColor = hasError ? '#ff4d4f' : '#faad14';
                style.color = 'white';
              }

              const [day, date] = formattedDateItems[0].split(' ')
              return (
                <th key={item.time} className={`header3-text`} style={style}>
                  {day} <b>{date}</b>
                  {/* {
                    formattedDateItems.map((formattedItem, index) => (
                      <div key={index}
                        dangerouslySetInnerHTML={{ __html: formattedItem.replace(/[0-9]/g, '<b>$&</b>') }} />
                    ))
                  } */}
                </th>
              );
            }}

            slotItemTemplateResolver={(schedulerData, item, slotClickedFunc, width, clsName) => {
              const task = tasksIdx[item.slotId]
              const duration = (task ? task.duration : 0) || 0
              const cost_expected = (task ? task.cost_expected : 0) || 0


              return <PlanningSlotHeaderItemRenderer
                schedulerData={schedulerData}
                item={item}
                slotClickedFunc={slotClickedFunc}
                width={width}
                className={clsName}
                resourcesIdx={{
                  ...projectsIdx,
                  ...tasksIdx,
                  ...prestationsIdx,
                  ...usersIdx,
                }}
                duration={sums[item.slotId]?.duration || 0}
                expectedDuration={duration}
                amount={sums[item.slotId]?.purchase_price || 0}
                expectedAmount={cost_expected}
              />

            }}
            eventItemPopoverTemplateResolver={(schedulerData, eventItem, title, start, end, statusColor) => {
              return <ResourcePopover
                schedulerData={schedulerData}
                eventItem={eventItem}
                title={title}
                start={start}
                end={end}
                statusColor={statusColor}
                errors={errorsIdx[eventItem.id]}

                slot={slotsIdx[eventItem.id]}
                user={usersIdx[slotsIdx[eventItem.id]?.resource_id]}
                task={tasksIdx[slotsIdx[eventItem.id]?.task_id]}

                users={users}
                tasks={tasks}

                onCancel={() => setCancelingSlot(eventItem.id)}
                onConfirm={() => onSetStatus(eventItem.id, 'confirmed')}
                onDone={() => onSetStatus(eventItem.id, 'done')}

              />
            }}
            eventItemTemplateResolver={(schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {

              if (event.milestone) {
                return <ResourceSlotMilestoneItem
                  schedulerData={schedulerData}
                  event={event}
                  bgColor={bgColor}
                  isStart={isStart}
                  isEnd={isEnd}
                  mustAddCssClass={mustAddCssClass}
                  mustBeHeight={mustBeHeight}
                  agendaMaxEventWidth={agendaMaxEventWidth}
                />
              }
              switch (mode) {
                case 'projects':
                  return <ResourceSlotEventItem
                    schedulerData={schedulerData}
                    event={event}
                    bgColor={bgColor}
                    isStart={isStart}
                    isEnd={isEnd}
                    mustAddCssClass={mustAddCssClass}
                    mustBeHeight={mustBeHeight}
                    agendaMaxEventWidth={agendaMaxEventWidth}
                    errors={errorsIdx[event.id]}
                    resourcesIdx={{
                      ...slotsIdx,
                      ...usersIdx,
                    }} />
                case 'resources':
                  // return null;
                  return <TaskSlotEventItem
                    schedulerData={schedulerData}
                    event={event}
                    bgColor={bgColor}
                    isStart={isStart}
                    isEnd={isEnd}
                    mustAddCssClass={mustAddCssClass}
                    mustBeHeight={mustBeHeight}
                    agendaMaxEventWidth={agendaMaxEventWidth}
                    errors={errorsIdx[event.id]}
                    resourcesIdx={{
                      ...slotsIdx,
                      ...usersIdx,
                    }} />
                default:
                  return null;
              }
            }
            }
          />
        </DndProvider>

        <Modal
          title={editedSlot?.task_id === "unavailable" ? "Indisponibilité" : tasksIdx[editedSlot?.task_id]?.name}
          centered
          open={!!editedSlot}
          onOk={onEditModalOk}
          confirmLoading={savingSlot}
          cancelText="Annuler"
          onCancel={() => {
            setEditedSlot(null)
            form.resetFields();
          }}
          footer={(_, { OkBtn, CancelBtn }) => (
            <Flex gap="small" justify="flex-end">
              {editedSlot && editedSlot.id && (
                <div style={{ flex: 1, textAlign: 'left' }}>
                  <Button danger loading={deletingSlot} onClick={() => onDeleteSlot()}>Supprimer</Button>
                </div>)}
              {!deletingSlot && <>
                <CancelBtn />
                <OkBtn />
              </>}
            </Flex>
          )}
        >
          {editedSlot ?
            <PlanningSlotForm
              form={form}
              slots={slots}
              slot={editedSlot}
              task={tasksIdx[editedSlot.task_id]}
              agencyId={projectsIdx[editedSlot.project_id]?.agency_id} /> : null}
        </Modal>

        <Modal
          title="Annulation du créneau"
          centered
          open={!!cancelingSlot}
          destroyOnClose={true}
          // width={400}
          // confirmLoading={cancelingSlot}
          onCancel={() => setCancelingSlot(null)}
          footer={(_, { OkButton }) => <></>}
        >
          <p>Indiquez la raison de l'annulation</p>
          <div style={{ paddingLeft: "3em" }}>
            <Radio.Group
              onChange={(e) => {
                onSetStatus(cancelingSlot, 'canceled', e.target.value)
                setCancelingSlot(null)
              }}>
              <Space direction="vertical">
                {slotCancelReasons.map(({ value, label }) =>
                  <Radio key={value} value={value}>{label}</Radio>)}
              </Space>
            </Radio.Group>
          </div>
        </Modal>
      </div>
    </div>
  </>);
}