import { fetchAuthSession } from 'aws-amplify/auth';
import { del, get, post, put } from 'aws-amplify/api';


export const HEADER_APP_JSON = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const SERVER_ERR_MSG = 'Bad response from server';

async function parseResponse(response) {
  return response.body.json();
  // const s = await response.text()
  // if (s !== "") {
  //   return JSON.parse(s)
  // }
  // return undefined
}

async function throwErrorFromResponse(response, def = SERVER_ERR_MSG) {
  let msg = def;
  try {
    const err = await parseResponse(response);
    msg = err.error || def;
  } catch (e) {
    return def;
  }
  throw new Error(msg);
}

async function readResponse(response) {
  if (response.status >= 400) {
    console.error(response);
    await throwErrorFromResponse(response);
  }
  return parseResponse(response);
}

const buildHeader = async (header) => ({ ...(header || HEADER_APP_JSON), Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}` });

export function urlJoin(base, url) {
  if (!base && url) {
    return url;
  }
  if (base && !url) {
    return base;
  }
  if (base && url) {
    let s = base.replace(/\/$/, '');
    if (/^[^?#/]/.test(url)) {
      s += '/';
    }
    return s + url; // .replace(/^\//, '')
  }
  return '';
}

export function withApi(name, prefix = '') {
  return {
    async post(url = '', body = undefined, header = {}) {
      console.log('REQUEST POST', name, urlJoin(prefix, url), body);
      const response = await post({
        apiName: name,
        path: urlJoin(prefix, url),
        options: {
          body,
          headers: await buildHeader(header),
        },
      }).response;
      console.log('POST', urlJoin(prefix, url), response);
      return readResponse(response);
    },

    async put(url = '', body = undefined, header = {}) {
      console.log('REQUEST PUT', name, urlJoin(prefix, url), body);
      const response = await put({
        apiName: name,
        path: urlJoin(prefix, url),
        options: {
          body,
          headers: await buildHeader(header),
        }
      }).response.catch((e) => {
        console.error('>>>', e.response.data.error);
        throw e;
      });
      console.log('RESPONSE PUT', urlJoin(prefix, url), response);
      return readResponse(response);
    },

    async get(url = '', header = {}, queryParams = undefined) {
      // console.log(await buildHeader(header));
      console.log('REQUEST GET', name, urlJoin(prefix, url), await buildHeader(header));
      const response = await get({
        apiName: name,
        path: urlJoin(prefix, url),
        options: {
          method: 'GET',
          headers: await buildHeader(header),
          queryParams,
        }
      }).response;
      console.log('GET', urlJoin(prefix, url), response);
      return readResponse(response);
    },

    async delete(url = '', header = {}) {
      console.log('REQUEST DELETE', name, urlJoin(prefix, url))
      const response = await del({
        apiName: name,
        path: urlJoin(prefix, url),
        options: {
          method: 'DELETE',
          headers: await buildHeader(header),
        }
      }).response;
      console.log('DELETE', urlJoin(prefix, url), response);
      return readResponse(response);
    },

  };
}
