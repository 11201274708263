
import './scss/app.scss';


import { Authenticator } from '@aws-amplify/ui-react';

// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';

import AppRouter from './AppRouter';
import { AppLoader } from './components/app/AppLoader';
import { message } from 'antd';


function App() {
  // const [_, contextHolder] = message.useMessage();
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <div className="App">
          {/* {contextHolder} */}
          {/* <h1>HELLO {user.attributes.email}
          {user.attributes.family_name} {user.attributes.given_name}</h1>
        <button onClick={e => signOut()}>Logout</button> */}
          <AppRouter />
          <AppLoader />
        </div>
      )}
    </Authenticator>
  );
}

export default App;
