
import { PlusCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';


function navLinkActive(baseCls, activeClass) {
  return ({ isActive }) =>
    clsx(baseCls, isActive && activeClass)

}


export default function TaskItem() {
  const { taskId } = useParams()

  const navigate = useNavigate();
  const open = (id) => {
    navigate(`./${id}`);
  };


  return (<>
    {taskId && taskId !== '*' ?
      <Flex>
        <ul className="nav nav-pills subnav mb-4" style={{ flex: 1 }}>
          <li className="nav-item">
            <NavLink to="details" className={navLinkActive("nav-link", "active")}>Détail</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="documents" className={navLinkActive("nav-link", "active")}>Documents</NavLink>
          </li>
        </ul>

        <Flex gap="small">
          <Button
            onClick={() => open("../*/details?type=after-sales&parent=" + taskId)}
            icon={<PlusOutlined />} >Créer un SAV</Button>
          <Button type="primary"
            onClick={() => open("../*/details?parent=" + taskId)} icon={<PlusOutlined />}>Créer une sous-tâche</Button>
        </Flex>
      </Flex>
      : null}
    <Outlet />
  </>);
}
