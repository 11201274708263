import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';

import Style from './Breadcrumbs.module.scss';

export function Breadcrumbs({ routes }) {
  const breadcrumbs = useReactRouterBreadcrumbs(routes, { disableDefaults: true });

  const ref = useRef();

  // useEffect(
  //   () => {
  //     const observer = new IntersectionObserver(
  //       ([e]) => {
  //         e.target.classList.toggle(Style.sticked, e.intersectionRatio < 1);
  //       },
  //       { threshold: [1] },
  //     );
  //     observer.observe(ref.current);
  //     return () => {
  //       observer.disconnect();
  //     };
  //   },

  //   [ref.current],
  // );

  return (
    <h3 ref={ref} className={clsx(Style.breadcrumbs)}>
      <ul className="breadcrumb">
        {breadcrumbs.map(({ match, breadcrumb }, i) => (
          <li className="breadcrumb-item" key={match.pathname}>
            {
              (i < breadcrumbs.length - 1)
                ? (
                  <NavLink to={match.pathname}>
                    {breadcrumb}
                  </NavLink>
                )
                : breadcrumb
            }

          </li>
        ))}
      </ul>
    </h3>
  );
}
