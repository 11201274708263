import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/slots');
const { invalidateCache, withCache } = cache();


function mapFromSlotModel(input) {
  return {
    ...input,
    start: new Date(input.start).toISOString(),
    end: new Date(input.end).toISOString(),
  };
}

function mapToSlotModel(input) {
  return {
    ...input,
    start: new Date(input.start),
    end: new Date(input.end),
  };
}

export async function listSlots({ task_id, resource_id, project_id }) {
  const items = await withCache(async () => {
    const { items } = await api.get('', {}, {
      task_id: task_id || '',
      resource_id: resource_id || '',
      project_id: project_id || '',
    });
    return items;
  })
  return items ? items.map(mapToSlotModel) : [];
}

export async function getSlot(id) {
  const item = await withCache(async () => {
    const { item } = await api.get(id);
    if (!item) {
      throw new Error('Slot not found');
    }
    return item;
  }, id)
  const slot = mapToSlotModel(item);
  return slot;
}

export async function createSlot(slot) {
  const { item } = await api.post('', { item: mapFromSlotModel(slot) });
  item && invalidateCache(item.id)
  return item ? mapToSlotModel(item) : undefined;
}

export async function updateSlot(slot) {
  const { item } = await api.put('', { item: mapFromSlotModel(slot) });
  invalidateCache(slot.id)
  return item ? mapToSlotModel(item) : undefined;
}

export async function removeSlot(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  return !!res;
}
