
import clsx from 'clsx';
import React from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';


function navLinkActive(baseCls, activeClass) {
  return ({ isActive }) =>
    clsx(baseCls, isActive && activeClass)

}


export default function ResourceItem() {
  const { resourceId } = useParams();
  return (<>
    {resourceId && resourceId !== '*' ? (
      <ul className="nav nav-pills subnav mb-4">
        <li className="nav-item">
          <NavLink to="details" className={navLinkActive("nav-link", "active")}>Détail</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="prestations" className={navLinkActive("nav-link", "active")}>Prestations</NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink to="slotaway" className={navLinkActive("nav-link", "active")}>Indisponibilités</NavLink>
        </li> */}
      </ul>) : null}
    <Outlet />
  </>);
}
