import { Button, Divider, Flex, Row, Space } from "antd";
import SlotStatusLabel from "../slots/SlotStatusLabel";
import { PlanningErrorItem } from "./PlanningErrorItem";

import Style from "./ResourcePopover.module.scss";
import ResourceIcon from "../resources/ResourceIcon";
import { formatDate, formatDateTime, formatNumber } from "../../lib/format";
import { CheckCircleOutlined, CheckOutlined, StopOutlined } from "@ant-design/icons";
import SlotCancelReasonLabel from "../slots/SlotCancelReasonLabel";

export default function ResourcePopover({
  schedulerData, eventItem, title, start, end, statusColor,
  slot, user,
  errors, users, tasks,
  onCancel, onConfirm, onDone
}) {


  if (eventItem.milestone) {
    return <div style={{ maxWidth: 300 }}>
      <h6 className={Style.title}>
        <span >{title}</span>
      </h6>
      <Space direction="vertical">
        <div className="popover-content">
          {formatDate(start.toDate())}
        </div>
      </Space>
    </div>
  }

  const durationInDays = Math.ceil(10 * slot.duration / 8) / 10
  return <div style={{ maxWidth: 300 }}>
    <h4 className={Style.title}>
      <ResourceIcon type={user?.type} size={1} />
      <span style={{ flex: 1, margin: '0 10px' }}>{title}</span>
      <SlotStatusLabel status={slot?.status} />
    </h4>
    {slot.status === 'canceled' && slot.cancel_reason &&
      <Space style={{ color: 'red', marginBottom: 5 }} align="middle">
        Cause de l'annulation :<span style={{ fontWeight: 500 }}>
          <SlotCancelReasonLabel value={slot.cancel_reason} />
        </span>
      </Space>}
    <Flex vertical gap="small">
      {errors?.map((error, i) => <PlanningErrorItem key={error.id} error={error} users={users} tasks={tasks} />)}
      <div className="popover-content">
        {formatDate(start.toDate())} <b>{start.format('HH:mm')}</b> - {formatDate(end.toDate())} <b>{end.format('HH:mm')}</b>
        <p>
          Durée : <b>{slot.duration}h</b> &mdash; <b>{durationInDays} jour{durationInDays > 1 ? 's' : ''}</b>
          <br />
          Coût : <b>{formatNumber(eventItem?.metrics.purchase_price_total)}€</b> à <b>{formatNumber(eventItem?.metrics.purchase_price)}€/jour</b>
        </p>
      </div>

    </Flex>
    <Flex gap="small" align="center" justify="space-between">
      {slot.status !== 'done' && slot.status !== 'canceled' &&
        <Button size="small" danger icon={<StopOutlined />} onClick={() => onCancel()}>Annuler</Button>}
      {slot.status === 'planned' &&
        <Button size="small" type="primary"
          icon={<CheckOutlined />} style={{ backgroundColor: "#235789" }}
          onClick={() => onConfirm()}>Confirmer</Button>}
      {slot.status === 'confirmed' &&
        <Button size="small" type="primary"
          icon={<CheckCircleOutlined />}
          style={{ backgroundColor: "#52C41A" }}
          onClick={() => onDone()}>Terminer</Button>}
    </Flex>
  </div>
}