import { mdiAccountCancelOutline, mdiSelectGroup, mdiStoreOutline } from "@mdi/js";
import Icon from "@mdi/react";
import PrestationIcon from "../prestations/PrestationIcon";
import { Badge, Flex, Popover, Space, Tag, Tooltip } from "antd";
import ResourceIcon from "../resources/ResourceIcon";
import { formatNumber } from "../../lib/format";

function CompletionLabel({ remaining = 0, unit = 'j' }) {
  return remaining !== 0 ? <Badge
    count={`${remaining > 0 ? '+' : ''}${formatNumber(remaining)}${unit}`}
    color={remaining < 0 ? '#faad14' : undefined} /> : null;
}

function SlotAgencyItemRenderer({ name }) {
  return (
    <div className="slot-cell">
      <Icon path={mdiStoreOutline} size={1} className="mr-2" />
      <span className="slot-text fw-bolder">{name}</span>
      {/* <CompletionLabel remaining={3} /> */}
    </div>
  );
}

function SlotProjectItemRenderer({ name }) {
  return (
    <div className="slot-cell">
      <Icon path={mdiSelectGroup} size={1} className="mr-2" />
      <span className="slot-text fw-bolder">{name}</span>
      {/* <CompletionLabel remaining={3} /> */}
    </div>
  );
}

function SlotTaskItemRenderer({ name, task, prestation = {}, duration, amount, expectedDuration, expectedAmount }) {
  let unit = 'j';
  if (task.unit === 'h') {
    unit = 'h';
  }
  return (
    <div className="slot-cell">
      <Popover placement="right" title={prestation.name}
        content={<div style={{ maxWidth: 400 }}>
          {task.description && <p>
            {task.description}
          </p>}
          <div>
            Vendu : <b>{expectedDuration} {unit}</b> / <b>{formatNumber(expectedAmount)} €</b>
          </div>

          <div>
            Plannifié : <b>{duration} {unit}</b> / <b>{formatNumber(amount)} €</b>
          </div>

        </div>}
      >
        <Flex gap="small" align="center" wrap="nowrap">
          <div>
            <PrestationIcon type={prestation.type} size={0.7} />
          </div>
          <div style={{ flex: 1, display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {name}
            {/* <span className="fst-italic fw-lighter">- {prestation.name}</span> */}
          </div>
          <div>
            {expectedDuration > 0
              ? <CompletionLabel remaining={duration - expectedDuration} unit={unit} />
              : (expectedAmount > 0
                ? <CompletionLabel remaining={amount - expectedAmount} unit="€" />
                : null)}
          </div>
        </Flex>
      </Popover>
    </div >
  );
}

function SlotResourceItemRenderer({ name, resource = {}, duration }) {

  const percent = Math.round(100 * duration / (resource.capacity || duration));

  let color = percent > 100 ? 'red' : (
    percent >= 80 ? 'orange' : 'green'
  )

  return (
    <div className="slot-cell">
      <Flex gap="small" align="center" wrap="nowrap">
        <ResourceIcon type={resource.type} size={0.7} />
        <span className="slot-text" style={{ flex: 1 }}>
          {name}
        </span>
        <Tooltip title={(percent || 0) + "%"}>
          <Badge color={color} count={`${duration} / ${resource.capacity}`} title="" />
        </Tooltip>
      </Flex>
    </div >
  );
}



export default function PlanningSlotHeaderItemRenderer({
  schedulerData,
  item,
  slotClickedFunc,
  width,
  className,
  resourcesIdx,
  duration,
  expectedDuration,
  amount,
  expectedAmount,
}) {
  const res = resourcesIdx[item.slotId]

  const isAgency = item.slotId.startsWith('agcy');
  const isProject = item.slotId.startsWith('prj');
  const isTask = item.slotId.startsWith('task');
  const isResource = item.slotId.startsWith('res');


  return (
    <div className={className} style={{ width: width, textAlign: 'left', paddingLeft: item.indent + 'em' }}>
      {(() => {
        switch (true) {
          case isAgency:
            return <SlotAgencyItemRenderer name={item.slotName} />
          case isProject:
            return <SlotProjectItemRenderer name={item.slotName} />
          case isTask:
            return <SlotTaskItemRenderer name={item.slotName} task={res}
              prestation={resourcesIdx[res.prestation_id]}
              duration={duration}
              expectedDuration={expectedDuration}
              amount={amount}
              expectedAmount={expectedAmount} />
          case isResource:
            return <SlotResourceItemRenderer name={item.slotName}
              resource={res} duration={duration} />
          default:
            return <div className="slot-cell">
              <Icon path={mdiAccountCancelOutline} size={1} className="mr-2" />
              <span className="slot-text">{item.slotName}</span>
            </div>
        }
      })()}
    </div>
  );
}
