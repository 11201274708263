import { useCallback, useEffect, useState } from 'react';

import {
  createProject, getProject, listProjects, removeProject, updateProject,
} from '../services/api/projects';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';
import { cache } from '../services/cache';
import {
  isRequired, validate, validateAll,
} from '../lib/validation';
import { useForm } from './form';
import { add } from 'date-fns';

const INVALIDATION_KEY = Symbol('projects');

const { invalidateCache, withCache } = cache();

function getDefaults() {
  return {
    code: "",
    name: "",
    description: "",
    commercialCode: "",
    customerCode: "",
    address: "",
    phone: "",

    expected_start_date: new Date(),
    expected_end_date: add(new Date(), { months: 6 }),
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getProject(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function useProjects() {
  const [projects, setProjects] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      try {
        // const terms = await withCache(async () => loaderWrap(listProjects()));
        const terms = await loaderWrap(listProjects());
        setProjects(terms);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);

  return [projects, refresh];
}

export function useProject(id) {
  const [project, setProject] = useState();

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      setProject(await getItem(id));
    })();
  }, [id, counter]);

  const saveProject = useCallback(async (item) => {
    if (!item) {
      throw new Error('No project');
    }

    item = {
      ...item,
      expected_start_date: new Date(item.expected_start_date).toISOString(),
      expected_end_date: new Date(item.expected_end_date).toISOString(),
    }

    let out;
    if (item.id) {
      out = await loaderWrap(updateProject(item));
    } else {
      out = await loaderWrap(createProject(item));
    }
    setProject(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deleteProject = useCallback(async () => {
    await loaderWrap(removeProject(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    project,
    reloadProject: () => invalidate(id),
    isNewProject: !project?.id,
    setProject,
    saveProject,
    deleteProject,
  };
}

export function projectValidator(values, name = undefined) {
  const rules = {
    name: [isRequired],
    description: [isRequired],
  };
  if (name) {
    const err = validate(values[name], ...rules[name] || []);
    return { [name]: err || null };
  }
  return validateAll(values, rules);
}

export function useProjectForm(id) {
  const { project, saveProject, deleteProject } = useProject(id);

  const {
    register,
    handleSubmit,
    errors, isValid,
    setValues, values,
  } = useForm({
    values: {
      ...getDefaults(),
    },
    validator: projectValidator,
    // reValidateMode: "onChange"
  });

  useEffect(() => {
    if (project) setValues(project);
  }, [project]);

  return {
    project,
    register,
    errors,
    isValid,
    values,
    // rules,

    handleSubmit,
    saveProject,
    deleteProject,
  };
}
