import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  createResource, getResource, listResources, removeResource, updateResource,
} from '../services/api/resources';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';
import { cache } from '../services/cache';
import {
  isRequired, validate, validateAll,
} from '../lib/validation';
import { useForm } from './form';


const INVALIDATION_KEY = Symbol('resources');

const { invalidateCache, withCache } = cache();

export const resourceTypes = [
  {
    label: 'Interne',
    value: 'internal',
  },
  {
    label: 'Externe',
    value: 'external',
  },
  {
    label: 'Client',
    value: 'customer',
  },
  {
    label: 'Autre',
    value: 'other',
  },
];

function getDefaults() {
  return {
    code: "",
    name: "",
    type: "internal",
    description: "",
    capacity: 0,
    enabled: true,
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getResource(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function useResources() {
  const [resources, setResources] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      try {
        // const terms = await withCache(async () => loaderWrap(listResources()));
        const terms = await loaderWrap(listResources());
        setResources(terms);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);

  return [resources, refresh];
}

export function useResource(id) {
  const [resource, setResource] = useState();

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      setResource(await getItem(id));
    })();
  }, [id, counter]);

  const saveResource = useCallback(async (item) => {
    if (!item) {
      throw new Error('No resource');
    }
    let out;
    if (item.id) {
      out = await loaderWrap(updateResource(item));
    } else {
      out = await loaderWrap(createResource(item));
    }
    setResource(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deleteResource = useCallback(async () => {
    await loaderWrap(removeResource(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    resource,
    reloadResource: () => invalidate(id),
    isNewResource: !resource?.id,
    setResource,
    saveResource,
    deleteResource,
  };
}


export function resourceValidator(values, name = undefined) {
  const rules = {
    name: [isRequired],
    code: [],
    type: [isRequired],
    capacity: [],
    description: [],
  };
  if (name) {
    const err = validate(values[name], ...rules[name] || []);
    return { [name]: err || null };
  }
  return validateAll(values, rules);
}

export function useResourceForm(id) {
  const { resource, saveResource, deleteResource } = useResource(id);

  const {
    register,
    handleSubmit,
    errors, isValid,
    setValues,

    values,
  } = useForm({
    values: {
      ...getDefaults(),
    },
    validator: resourceValidator,
    // reValidateMode: "onChange"
  });

  useEffect(() => {
    if (resource) setValues(resource);
  }, [resource]);


  return {
    resource,
    register,
    errors,
    isValid,
    // rules,

    handleSubmit,
    saveResource: async () => {
      const data = {
        ...values,
      };
      await saveResource(data);
    },
    deleteResource,
  };
}


