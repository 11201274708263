import { useCallback, useEffect, useState } from 'react';

import {
  createTask, getTask, listTasks, removeTask, updateTask,
} from '../services/api/tasks';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';
import { cache } from '../services/cache';
import {
  isRequired, validate, validateAll,
} from '../lib/validation';
import { useForm } from './form';

const INVALIDATION_KEY = Symbol('tasks');




export const taskTypes = [
  {
    label: 'Tâche',
    value: 'task',
  },
  {
    label: 'Prestation',
    value: 'prestation',
    color: 'blue'
  },
  {
    label: 'Service après-vente',
    value: 'after-sales',
    color: 'gold'
  },
];



export const taskStatuses = [
  {
    label: 'À planifier',
    value: 'unplanned',
    color: 'default'
  },
  {
    label: "Planification",
    value: 'inplanning',
    color: '#1677ff'
  },
  {
    label: "Planifiée",
    value: 'planned',
    color: '#13C2C2'
  },
  {
    label: "Bloquée",
    value: 'blocked',
    color: 'orange'
  },
  {
    label: "Annulée",
    value: 'canceled',
    color: 'red'
  },
  {
    label: "Terminé",
    value: 'done',
    color: '#52C41A'
  }
];

export const taskUnits = [
  {
    label: 'Jours',
    value: 'd',
  },
  {
    label: 'Heures',
    value: 'h',
  },
  {
    label: 'Minutes',
    value: 'm',
  },
];

export const taskPriorities = [
  {
    label: 'Basse',
    value: 1,
  },
  {
    label: 'Moyenne',
    value: 2,
  },
  {
    label: 'Haute',
    value: 4,
  },
];



function getDefaults() {
  return {
    name: "",
    type: "prestation",
    description: "",
    duration: null,
    unit: "d",
    status: "unplanned",
    cost: null,
    parent_task_id: null,
    previous_task_ids: [],
    minDate: null,
    maxDate: null,
    expected_level: 1,
    priority: 1,
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getTask(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function useTasks() {
  const [tasks, setTasks] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      try {
        // const terms = await withCache(async () => loaderWrap(listTasks()));
        const terms = await loaderWrap(listTasks());
        setTasks(terms);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);

  return [tasks, refresh];
}

export function useTask(id, { parent, type } = {}) {
  const [task, setTask] = useState();

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      const item = await getItem(id);
      if (!item.id) {
        if (parent) {
          item.parent_task_id = parent;
        }
        if (type) {
          item.type = type;
        }
      }
      setTask(item);
    })();
  }, [id, counter, parent, type]);

  const saveTask = useCallback(async (item) => {
    if (!item) {
      throw new Error('No task');
    }
    let out;
    if (item.id) {
      out = await loaderWrap(updateTask(item));
    } else {
      out = await loaderWrap(createTask(item));
    }
    setTask(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deleteTask = useCallback(async () => {
    await loaderWrap(removeTask(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    task,
    reloadTask: () => invalidate(id),
    isNewTask: !task?.id,
    setTask,
    saveTask,
    deleteTask,
  };
}

export function taskValidator(values, name = undefined) {
  const rules = {
    name: [isRequired],
    description: [],
  };
  if (name) {
    const err = validate(values[name], ...rules[name] || []);
    return { [name]: err || null };
  }
  return validateAll(values, rules);
}

// export function useTaskForm(id) {
//   const { task, saveTask, deleteTask } = useTask(id);

//   const {
//     register,
//     handleSubmit,
//     errors, isValid,
//     setValues,
//   } = useForm({
//     values: {
//       ...getDefaults(),
//     },
//     validator: taskValidator,
//     // onChange: (name, value, newValues, formState) => {
//     //   if (name === 'prestation_id' && !formState.name) {
//     //     const prestation = formState.prestations.find((p) => p.id === value);
//     //     if (prestation) {
//     //       newValues.name = prestation.name;
//     //       newValues.description = prestation.description;
//     //     }
//     //   }
//     //   return newValues;
//     // }
//     // reValidateMode: "onChange"
//   });

//   useEffect(() => {
//     if (task) setValues(task);
//   }, [task]);

//   return {
//     task,
//     register,
//     errors,
//     isValid,
//     // rules,

//     handleSubmit,
//     saveTask,
//     deleteTask,
//   };
// }
