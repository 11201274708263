import { mdiArrowExpandLeft, mdiArrowExpandRight, mdiFlagCheckered, mdiFlagOutline, mdiRayEnd, mdiRayStart } from "@mdi/js";

import Style from "./EventItem.module.scss";
import Icon from "@mdi/react";



export default function ResourceSlotMilestoneItem({
  schedulerData,
  event,
  bgColor,
  isStart,
  isEnd,
  mustAddCssClass,
  mustBeHeight,
  agendaMaxEventWidth,
  resourcesIdx,
}) {
  const { milestone } = event;

  return <div className={{}} key={event.id}
    style={{ maxHeight: mustBeHeight, textAlign: milestone === 'start' ? 'left' : 'right' }}>
    <Icon path={milestone === 'start' ? mdiArrowExpandRight : mdiArrowExpandLeft} size={0.8} color={milestone === 'start' ? 'green' : 'red'} />
  </div >
}