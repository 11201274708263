import { Affix, Flex } from "antd";
import { Breadcrumbs } from "../breadcrumb/Breadcrumbs";
import { routes } from "../../routes";
import { useEffect, useRef, useState } from "react";

import Style from "./AppTop.module.scss";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import AgencySelector from "../agencies/AgencySelector";

export default function AppTop() {
  const [sticked, setSticked] = useState(false);
  const [userName, setUserName] = useState("");
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    (async () => {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const claims = idToken?.payload;

      if (claims) {
        setUserName([claims.given_name, claims.family_name].join(" "));
      }
    })()
    // console.log(await getCurrentUser())
  }, [user.userId]);

  // const ref = useRef();
  // useEffect(
  //   () => {
  //     if (!ref.current) return;
  //     const observer = new IntersectionObserver(
  //       ([e]) => {
  //         e.target.classList.toggle(Style.sticked, e.intersectionRatio < 1);
  //       },
  //       { threshold: [1] },
  //     );
  //     observer.observe(ref.current);
  //     return () => {
  //       observer.disconnect();
  //     };
  //   },
  //   [ref.current],
  // );

  console.log(user)

  return (
    <Affix offsetTop={0} onChange={(affixed) => setSticked(affixed)}
      target={() => document.getElementById("app-main")}>
      <div className={Style.top + (sticked ? " " + Style.sticked : "")}>
        <Flex align="center" justify="space-between" >
          <Breadcrumbs routes={routes} />
          <Flex align="center" justify="space-between">
            <div className={Style.username}>{userName}</div>
            {/* -
            <div className={Style.agency}><AgencySelector /></div> */}
          </Flex>
        </Flex>
      </div>
    </Affix>
  );
}