import React, { useCallback, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ID } from '../../components/id/id';
import { useProjects } from '../../hooks/projects';
import { useSearcher } from '../../hooks/search';
import { Button, Flex, Input, Space, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useAgencies } from '../../hooks/agencies';
import { indexBy } from '../../lib/utils';

export default function ProjectsList() {
  const [projects] = useProjects();
  const [agencies] = useAgencies();

  const { filteredList, search, setSearch } = useSearcher(
    projects,
    useCallback((o, search) => o.name.toLowerCase().includes(search.toLowerCase()), []),
  );



  const navigate = useNavigate();
  const open = (id) => {
    navigate(`./${id}`);
  };

  const items = useMemo(() => {
    const agencyIdx = indexBy(agencies, "id")
    return filteredList.map((item) => {
      const agency = agencyIdx[item.agency_id];
      return {
        ...item,
        agency_name: agency?.name,
      };
    });
  }, [filteredList, agencies]);

  return (<>
    <div className="box">
      <Flex gap="small" align='center' >
        <Input size="large" placeholder="Rechercher une affaire..."
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button type="primary" onClick={() => open("*")}>Créer une affaire</Button>
      </Flex>
    </div>
    <div className="box-paddingless">

      <Table
        // bordered
        dataSource={items}
        rowKey={(record) => record.id}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => open(record.id), // click row
          };
        }}
        columns={[
          {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            width: 100,
            sorter: (a, b) => String(a.code).localeCompare(b.code),
          },
          {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => String(a.name).localeCompare(b.name),
          },
          {
            title: 'Agence',
            dataIndex: 'agency_name',
            key: 'agency_name',
            sorter: (a, b) => String(a.agency_id).localeCompare(b.agency_id),
          }
        ]}
      />
    </div>

  </>);
}
