import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/tasks');

const { invalidateCache, withCache } = cache();

function mapFromTaskModel(input) {
  return { ...input };;
}

function mapToTaskModel(input) {
  return { ...input };;
}

export async function listTasks() {
  const items = await withCache(async () => {
    const { items } = await api.get();
    return items;
  }, '')
  return items ? items.map(mapToTaskModel) : [];
}

export async function getTask(id) {
  const item = await withCache(async () => {
    const { item } = await api.get(id);
    if (!item) {
      throw new Error('Task not found');
    }
    return item;
  }, id)
  const task = mapToTaskModel(item);
  return task;
}

export async function createTask(task) {
  const { item } = await api.post('', { item: mapFromTaskModel(task) });
  if (item) {
    invalidateCache(item.id)
    return mapToTaskModel(item)
  }
  return undefined;
}

export async function updateTask(task) {
  const res = await api.put('', { item: mapFromTaskModel(task) });
  invalidateCache(task.id)
  return res ? task : undefined;
}

export async function removeTask(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  return !!res;
}


export async function createImportTask() {
  const { id, url } = await api.post('/import');
  return { id, url }
}



export async function startImportTask(importId) {
  const { imported } = await api.post('/import/' + importId);
  invalidateCache()
  return imported
}