import { useCallback, useEffect, useState } from 'react';

import {
  createPrestation, getPrestation, listPrestations, removePrestation, updatePrestation,
} from '../services/api/prestations';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';
import { cache } from '../services/cache';
import {
  isRequired, validate, validateAll,
} from '../lib/validation';
import { useForm } from './form';

const INVALIDATION_KEY = Symbol('prestations');


export const prestationLevels = [
  {
    label: 'Basique',
    value: 1,
  },
  {
    label: 'Avancé',
    value: 3,
  },
  {
    label: 'Expert',
    value: 5,
  },
];


export const prestationsTypes = [
  {
    label: 'Service',
    value: 'service',
  },
  {
    label: 'Produit',
    value: 'product',
  },
  {
    label: 'Location',
    value: 'rental',
  },
  {
    label: 'Autre',
    value: 'other',
  },
];


const { invalidateCache, withCache } = cache();

function getDefaults() {
  return {
    type: 'service',
    code: '',
    name: '',
    description: '',
    price: 0,
    purchase_price: 0,
    min: 1,
    max: 5,
    default_qty: 1,
    delay: 0,
    enabled: true,

    prestations: [],
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getPrestation(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function usePrestations() {
  const [prestations, setPrestations] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      try {
        // const terms = await withCache(async () => loaderWrap(listPrestations()));
        const terms = await loaderWrap(listPrestations());
        setPrestations(terms);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);

  return [prestations, refresh];
}

export function usePrestation(id) {
  const [prestation, setPrestation] = useState();

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      setPrestation(await getItem(id));
    })();
  }, [id, counter]);

  const savePrestation = useCallback(async (item) => {
    if (!item) {
      throw new Error('No prestation');
    }
    let out;
    if (item.id) {
      out = await loaderWrap(updatePrestation(item));
    } else {
      out = await loaderWrap(createPrestation(item));
    }
    setPrestation(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deletePrestation = useCallback(async () => {
    await loaderWrap(removePrestation(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    prestation,
    reloadPrestation: () => invalidate(id),
    isNewPrestation: !prestation?.id,
    setPrestation,
    savePrestation,
    deletePrestation,
  };
}

export function prestationValidator(values, name = undefined) {
  const rules = {
    name: [isRequired],
    type: [isRequired],
    description: [],
  };
  if (name) {
    const err = validate(values[name], ...rules[name] || []);
    return { [name]: err || null };
  }
  return validateAll(values, rules);
}

export function usePrestationForm(id) {
  const { prestation, savePrestation, deletePrestation } = usePrestation(id);

  const {
    register,
    handleSubmit,
    errors, isValid,
    setValues,
    values,
  } = useForm({
    values: {
      ...getDefaults(),
    },
    validator: prestationValidator,
    // reValidateMode: "onChange"
  });

  useEffect(() => {
    if (prestation) setValues(prestation);
  }, [prestation]);

  return {
    prestation,
    prestationForm: values,
    register,
    errors,
    isValid,
    // rules,

    handleSubmit,
    savePrestation,
    deletePrestation,
  };
}
