import { mdiAccountHardHatOutline, mdiChatQuestion, mdiDrawing, mdiProgressWrench, mdiSelectGroup, mdiStoreOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Input, Select, Tag } from "antd";
import { useEffect, useMemo, useState } from "react";
import { indexBy } from "../../lib/utils";


function asIconAndColor(type) {
  switch (type) {
    case "agency":
      return { icon: mdiStoreOutline, color: "blue" };
    case "project":
      return { icon: mdiSelectGroup, color: "green" };
    case "resource":
      return { icon: mdiAccountHardHatOutline, color: "purple" };
    case "task":
      return { icon: mdiDrawing, color: "orange" };
    case "prestation":
      return { icon: mdiProgressWrench, color: "gold" };
    default:
      return { icon: null, color: "grey" };
  }
}


function TagRenderer({ label, type, closable, onClose }) {
  const { icon, color } = asIconAndColor(type);
  return <Tag
    color={color}
    // onMouseDown={onPreventMouseDown}
    closable={closable}
    onClose={onClose}
    style={{ marginRight: 3 }}
  >
    {icon && <Icon path={icon} size={0.8} style={{ marginRight: 3 }} />}
    {label}
  </Tag>
}


function OptionRenderer({ data }) {
  const { type, label } = data;
  const { icon, color } = asIconAndColor(type);
  return <span style={{ color }}>
    <Icon path={icon} size={0.8} color={color} style={{ marginRight: 3 }} />
    {label}
  </span>
}

// const filtersByType = {
//   agencies: "agency",
//   projects: "project",
//   resources: "resource",
//   tasks: "task",
//   prestations: "prestation",
// };

const typesToFilter = {
  agency: "agencies",
  project: "projects",
  resource: "resources",
  task: "tasks",
  prestation: "prestations",
};

const filterOption = (input, option) => {
  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());;
}

export default function Search({
  agencies = [],
  projects = [],
  resources = [],
  tasks = [],
  prestations = [],

  values: inputValues = {},

  onChange
}) {

  const [values, setValues] = useState([]);


  useEffect(() => {
    if (JSON.stringify(inputValues?.values || [])
      !== JSON.stringify(values)) {
      setValues(inputValues.values || []);
    }
  }, [inputValues, values]);


  const [options, idx] = useMemo(() => {
    const agenciesOptions = agencies.map((o) => ({ value: o.id, label: o.name, type: "agency" }));
    const projectsOptions = projects.map((o) => ({ value: o.id, label: o.name, type: "project" }));
    const resourcesOptions = resources.map((o) => ({ value: o.id, label: o.name, type: "resource" }));
    const tasksOptions = tasks.map((o) => ({ value: o.id, label: o.name, type: "task" }));
    const prestationsOptions = prestations.map((o) => ({ value: o.id, label: o.name, type: "prestation" }));

    const options = [
      ...agenciesOptions,
      ...projectsOptions,
      ...resourcesOptions,
      ...tasksOptions,
      ...prestationsOptions,
    ];

    return [options, indexBy(options, "value")];
  }, [agencies, projects, resources, tasks, prestations]);


  const onChangeHandler = (values) => {
    const filters = values.reduce((filters, value) => {
      const { type } = idx[value];
      const filter = typesToFilter[type];
      if (!filters[filter]) {
        filters[filter] = [];
      }
      filters[filter].push(value);
      return filters;
    }, { values });
    onChange(filters);
  }

  return <Select
    style={{ width: '100%' }}
    mode="multiple"
    optionRender={OptionRenderer}
    tagRender={({ label, value, closable, onClose }) => TagRenderer({ label, type: idx[value]?.type, value, closable, onClose })}
    placeholder="Rechercher..."
    options={options}
    allowClear
    value={values}
    filterOption={filterOption}
    onChange={onChangeHandler}
  />
}