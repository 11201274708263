import { useMemo, useState } from "react";
import { useTasks } from "../../hooks/tasks";
import { useResources } from "../../hooks/resources";
import { useProjects } from "../../hooks/projects";
import { usePrestations } from "../../hooks/prestations";
import { useAgencies } from "../../hooks/agencies";
import { indexBy } from "../../lib/utils";
import { slotStatuses, useSlot, useSlotsCollection, useSlotsValidator } from "../../hooks/slots";
import { roundToEndWorkTimeIfNeeded, roundToStartWorkTimeIfNeeded } from "../../components/planning/utils";
import { DATETIME_FORMAT } from "../../components/scheduler/Scheduler";
import dayjs from "dayjs";
import { useResourcePrestations } from "../../hooks/resource-prestations";

function applyEventLocks(events) {
  return events.map(event => {
    const lock = !!event.milestone || event.slot?.status === 'canceled' || event.slot?.status === 'done';
    return {
      ...event,
      movable: !lock,
      startResizable: !lock,
      endResizable: !lock,
    }
  });
}

export function usePlanningDatastore() {
  const [tasks] = useTasks()
  const [users] = useResources()
  const [projects] = useProjects()
  const [prestations] = usePrestations()
  const [resourcePrestations] = useResourcePrestations()
  const [agencies] = useAgencies()

  const { slots, addSlot, deleteSlot, updateSlot } = useSlotsCollection()
  // const { saveSlot } = useSlot()


  const tasksIdx = useMemo(() => {
    return indexBy(tasks || [], "id") || {};
  }, [tasks])

  const usersIdx = useMemo(() => {
    return indexBy(users || [], "id") || {};
  }, [users])

  const projectsIdx = useMemo(() => {
    return indexBy(projects || [], "id") || {};
  }, [projects])

  const prestationsIdx = useMemo(() => {
    return indexBy(prestations || [], "id") || {};
  }, [prestations])

  const slotsIdx = useMemo(() => {
    return indexBy(slots || [], "id") || {};
  }, [slots])

  const resourcesPrestationsIdx = useMemo(() => {
    return indexBy(resourcePrestations || [], ({ resource_id, prestation_id }) => [resource_id, prestation_id]) || {};
  }, [resourcePrestations])


  return {
    tasks,
    getTask: (id) => tasksIdx[id],
    users,
    getUser: (id) => usersIdx[id],
    projects,
    getProject: (id) => projectsIdx[id],
    prestations,
    getPrestation: (id) => prestationsIdx[id],
    resourcePrestations,
    getResourcePrestation: (resource_id, prestation_id) => resourcesPrestationsIdx[[resource_id, prestation_id]],

    agencies,
    slots,
    slotsIdx,

    addSlot,
    deleteSlot,
    updateSlot,
    // saveSlot,
  }

}


export function useByProjects({
  tasks, projects, slots, agencies,
  getUser, getTask, getPrestation, getResourcePrestation }, filters = {}) {


  const resources = useMemo(() => {
    if (!tasks || !projects || !slots || !agencies) {
      return [];
    }

    const resources = [];
    (agencies || []).forEach(agency => {
      if (filters.agencies && !filters.agencies.includes(agency.id)) return;
      resources.push({
        id: agency.id,
        name: agency.name,
        groupOnly: true,
        agency,
      });
    });

    (projects || []).forEach(project => {
      if (filters.agencies && !filters.agencies.includes(project.agency_id)) return;
      if (filters.projects && !filters.projects.includes(project.id)) return;
      resources.push({
        id: project.id,
        name: project.name,
        groupOnly: true,
        parentId: project.agency_id,
        project,
        // styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
      })
    });

    (tasks || []).forEach(task => {
      if (filters.projects && !filters.projects.includes(task.project_id)) return;
      if (filters.tasks && !filters.tasks.includes(task.id)) return;
      if (filters.prestations && !filters.prestations.includes(task.prestation_id)) return;
      resources.push({
        id: task.id,
        name: task.name,
        parentId: task.parent_task_id || task.project_id,
        task,
        // styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
      })
    });

    resources.push({
      id: "unavailable",
      name: "Indisponible",
    })
    return resources;

  }, [tasks, projects, agencies, slots, filters])

  const { events, sums } = useMemo(() => {
    const events = [];
    const sums = {};
    const slotStatusIdx = indexBy(slotStatuses, "value");

    // const usersIdx = indexBy(users || [], "id") || {};
    // const tasksIdx = indexBy(tasks || [], "id") || {};

    (projects || []).forEach(project => {
      if (project.expected_start_date) {
        events.push({
          id: project.id + '-start',
          movable: false,
          startResizable: false,
          endResizable: false,
          title: "Début de l'affaire",
          start: roundToStartWorkTimeIfNeeded(project.expected_start_date).format(DATETIME_FORMAT),
          end: roundToStartWorkTimeIfNeeded(project.expected_start_date).format(DATETIME_FORMAT),
          resourceId: project.id,
          milestone: 'start',
        })
      }

      if (project.expected_end_date) {
        events.push({
          id: project.id + '-end',
          movable: false,
          startResizable: false,
          endResizable: false,
          title: "Fin de l'affaire",
          start: roundToEndWorkTimeIfNeeded(project.expected_end_date).format(DATETIME_FORMAT),
          end: roundToEndWorkTimeIfNeeded(project.expected_end_date).format(DATETIME_FORMAT),
          resourceId: project.id,
          milestone: 'end',
        })
      }
    });


    (slots || []).forEach(slot => {
      if (filters.resources && !filters.resources.includes(slot.resource_id)) return;

      const task = getTask(slot.task_id) || { id: slot.task_id };
      const user = getUser(slot.resource_id) || { id: slot.resource_id };
      const presRes = getResourcePrestation(slot.resource_id, task.prestation_id);
      const pres = getPrestation(task.prestation_id);

      const status = slotStatusIdx[slot.status] || {};

      let duration = slot.duration || 0;
      if (task.unit === 'd') {
        duration = Math.round(10 * duration / 8) / 10;
      }
      if (slot.task_id === 'task-3E44VX7QEV') {
        console.log(slot, slot.resource_id, task.prestation_id)
      }
      const purchase_price = (presRes?.purchase_price || pres?.purchase_price || 0);
      const purchase_price_total = duration * purchase_price;


      if (slot.status !== 'canceled') {
        sums[slot.task_id] = sums[slot.task_id] || { duration: 0, purchase_price: 0 };
        sums[slot.task_id].duration += duration;
        sums[slot.task_id].purchase_price += purchase_price_total;
      }

      events.push({
        id: slot.id,
        start: roundToStartWorkTimeIfNeeded(slot.start).format(DATETIME_FORMAT),
        end: roundToEndWorkTimeIfNeeded(slot.end).format(DATETIME_FORMAT),
        title: (user.name || task?.name) || '',
        resourceId: task.id,
        slot,
        metrics: {
          duration,
          purchase_price,
          purchase_price_total,
        },
        // movable: status.movable && new dayjs(slot.end).isAfter(new dayjs()),
        bgColor: slot.task_id === 'unavailable' ? '#95a5a6' : status.color,
      })
    })


    return { events: applyEventLocks(events), sums };
  }, [slots, projects, filters])


  return { resources, events, sums }
}


export function useByResources({ tasks, slots, agencies, users,
  getUser, getTask, getPrestation, getResourcePrestation
}, filters = {}) {
  const resources = useMemo(() => {
    if (!users || !agencies) {
      return [];
    }
    const resources = [];
    agencies?.forEach(agency => {
      if (filters.agencies && !filters.agencies.includes(agency.id)) return;
      resources.push({
        id: agency.id,
        name: agency.name,
        groupOnly: true,
        agency,
      });
    });
    users?.forEach(user => {
      if (filters.agencies && !filters.agencies.includes(user.agency_id)) return;
      if (filters.resources && !filters.resources.includes(user.id)) return;
      resources.push({
        id: user.id,
        name: user.name,
        parentId: user.agency_id,
        resource: user,
      })
    });
    return resources;

  }, [users, agencies, filters])

  const { events, sums } = useMemo(() => {
    const events = [];
    const sums = {};


    const slotStatusIdx = indexBy(slotStatuses, "value")


    slots?.forEach(slot => {
      const task = getTask(slot.task_id);
      if (!task) return;
      if (filters.projects && !filters.projects.includes(task.project_id)) return;
      if (filters.tasks && !filters.tasks.includes(slot.task_id)) return;
      if (filters.prestations && !filters.prestations.includes(task.prestation_id)) return;
      const status = slotStatusIdx[slot.status] || {};

      let duration = slot.duration || 0;
      if (task.unit === 'd') {
        duration = Math.round(10 * duration / 8) / 10;
      }

      const presRes = getResourcePrestation(slot.resource_id, task.prestation_id);
      const pres = getPrestation(task.prestation_id);

      const purchase_price = (presRes?.purchase_price || pres?.purchase_price || 0);
      const purchase_price_total = duration * purchase_price;

      events.push({
        id: slot.id,
        start: roundToStartWorkTimeIfNeeded(slot.start).format(DATETIME_FORMAT),
        end: roundToEndWorkTimeIfNeeded(slot.end).format(DATETIME_FORMAT),
        title: task.name || '',
        resourceId: slot.resource_id,
        slot,
        movable: false,
        startResizable: false,
        endResizable: false,
        metrics: {
          duration,
          purchase_price,
          purchase_price_total,
        },
        // movable: status.movable && new dayjs(slot.end).isAfter(new dayjs()),
        bgColor: status.color,
      })

      sums[slot.resource_id] = sums[slot.resource_id] || { duration: 0, purchase_price: 0 };
      sums[slot.resource_id].duration += new dayjs(slot.end).diff(slot.start, 'days') + 1;
    })
    return { events: events, sums };
  }, [slots, filters])


  return { resources, events, sums }

}