import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  listResourcePrestations, listResourcePrestationsForPrestation, listResourcePrestationsForResource, putResourcePrestations,
} from '../services/api/resources-prestations';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';

import {
  isRequired, validate, validateAll,
} from '../lib/validation';
import { useForm } from './form';

const INVALIDATION_KEY = Symbol('resourcePrestations');



function getDefaults() {
  return {
    id: '',
    resource_id: '',
    prestation_id: '',
    level: 1,
    price: null,
    purchase_price: null,
    enabled: true,
  };
}


export function useResourcePrestations({ prestationId, resourceId } = {}) {
  const [resourcePrestations, setResourcePrestations] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      try {
        let items = [];
        if (prestationId) {
          items = await loaderWrap(listResourcePrestationsForPrestation(prestationId));
        }
        else if (resourceId) {
          items = await loaderWrap(listResourcePrestationsForResource(resourceId));
        } else {
          items = await loaderWrap(listResourcePrestations());
        }
        setResourcePrestations(items);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);


  return [resourcePrestations, refresh];
}


export function resourcePrestationValidator(values, name = undefined) {
  const rules = {
    name: [isRequired],
    type: [isRequired],
    description: [],
  };
  if (name) {
    const err = validate(values[name], ...rules[name] || []);
    return { [name]: err || null };
  }
  return validateAll(values, rules);
}

export function useResourcePrestationsForm(id) {
  const {
    register,
    handleSubmit,
    errors, isValid,
    setValues,
    values,
  } = useForm({
    values: {
      items: [],
    },
    validator: resourcePrestationValidator,
    // reValidateMode: "onChange"
  });

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  const items = useMemo(() => {
    return values.items || [];
  }, [values]);

  useEffect(() => {
    (async () => {
      setValues({
        items: await listResourcePrestationsForResource(id)
      });
    })();
  }, [id, counter]);

  const save = useCallback(async ({ items }) => {
    if (!items) {
      throw new Error('No resource');
    }
    items = items.map((p) => ({
      ...p,
      level: +p.level,
      price: p.price ? +p.price : undefined,
      purchase_price: p.purchase_price ? +p.purchase_price : undefined,
    }));
    let out = await loaderWrap(putResourcePrestations(id, items));
    // setResource(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);


  return {
    register,
    errors,
    isValid: true,
    // rules,

    handleSubmit,
    saveResourcePrestations: async () => {
      const data = {
        items: items.map((p) => p),
      };
      await save(data);
    },

    // Prestations
    items: items,

    updatePrestation: (prestation) => {
      const items = values.items || [];
      const index = items.findIndex((p) => p.prestation_id === prestation.prestation_id);
      console.log(prestation, index)
      if (index >= 0) {
        items[index] = prestation;
        setValues({ ...values, items: [...items] });
      }
    },
    addPrestation: (prestation) => {
      const items = values.items || [];
      items.push({
        resource_id: id,
        ...prestation
      });
      setValues({ ...values, items: [...items] });
    },

    deletePrestation: (prestation) => {
      const items = (values.items || []).filter((p) => p.prestation_id === prestation.prestation_id);
      setValues({ ...values, items: [...items] });
    },

  };
}


