import { useMemo } from "react"
import { taskStatuses } from "../../hooks/tasks"
import { Flex, Progress, Space, Tag } from "antd"


export default function TaskStatusLabel({ value, progress }) {
  const { label, color } = useMemo(() => {
    return taskStatuses.find(s => s.value === value) || { label: value }
  }, [value])
  return <Tag color={color || 'default'}>
    <Flex align="center" gap={3}>
      {label}
      {progress !== undefined ? <Progress
        type="circle"
        strokeColor={"#ffffffee"}
        trailColor="#ffffff55"
        strokeWidth={15}
        percent={progress}
        size={14}
      /> : null}
    </Flex>
  </Tag>
}
