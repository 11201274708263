import { useEffect, useMemo, useState } from 'react';

export function useSearcher(list, filter) {
  const [search, setSearch] = useState('');

  const filteredList = useMemo(() => {
    const l = list.filter((item) => filter(item, search));
    return l
  }, [list, search, filter]);

  return {
    filteredList,
    search,
    setSearch,
  };
}
