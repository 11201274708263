import { DatePicker, Divider, Flex, Form, Rate } from "antd"
import dayjs from "dayjs"
import { useMemo } from "react"
import SlotStatusInput from "../slots/SlotStatusInput"
import { ResourceForPrestationInput } from "../resources/ResourceForPrestationInput"
import SlotCancelReasonInput from "../slots/SlotCancelReasonInput"
import SlotStatusLabel from "../slots/SlotStatusLabel"


export default function PlanningSlotForm({ form, slot, slots, task }) {

  const busyResources = useMemo(() => {
    const start = new dayjs(slot.start)
    const end = new dayjs(slot.end)
    return Array.from(new Set(slots
      .filter(s => {
        if (s.id === slot.id) {
          return false; // it's the current slot, ignore it
        }
        if (start.isSameOrAfter(s.end)) {
          return false; // it's before the slot, ignore it
        }
        if (end.isSameOrBefore(s.start)) {
          return false; // it's after the slot, ignore it
        }
        return true;
      })
      .map(s => s.resource_id)))
  }, [slot, slots])

  return <Form
    form={form}
    labelCol={{ span: 5 }}>
    {task ? <>
      <Form.Item>
        <Flex justify="space-between" align="center">
          <Rate disabled value={Math.ceil(task?.expected_level * 3 / 5)} style={{ fontSize: '0.8em' }} count={3} />
          <SlotStatusLabel status={slot?.status} />
        </Flex>
        {task?.description && <div className="form-text">{task?.description}</div>}
      </Form.Item>
      <Divider />
    </> : null}
    <Form.Item label="Date" name="dates" rules={[{ required: true, message: "Veuillez spécifier un intervale de date" }]}>
      <DatePicker.RangePicker format={"DD/MM/YYYY HH:mm"} showTime={{ format: 'HH:mm', minuteStep: 15 }} />
    </Form.Item>

    <Form.Item label={task ? "Affecté à" : "Ressource"} name="resource_id" rules={[{ required: true, message: "Veuillez spécifier une ressource affectée" }]}>
      <ResourceForPrestationInput
        prestationId={task?.prestation_id}
        agencyId={task?.agency_id}
        expectedLevel={task?.expected_level}
        busyResources={busyResources} />
    </Form.Item>
  </Form>
}