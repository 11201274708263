
import { useNavigate, useParams } from 'react-router-dom';
import DeleteButton from '../../components/deletebutton/DeleteButton';
import { useProject, useProjectForm } from '../../hooks/projects';
import { notifyError, notifySuccess } from '../../services/notification';
import { ControlBar } from '../../components/controlbar/ControlBar';
import DateInput from '../../components/inputs/DateInput';
import { DatePicker, Form, Input, InputNumber, message } from 'antd';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { AgencyInput } from '../../components/agencies/AgencyInput';
import TextArea from 'antd/es/input/TextArea';

// import { SubjectTimeline } from '../../components/dataviz/SubjectTimeline';
// import { ControlBar } from '../../components/controlbar/ControlBar';

export default function ProjectForm() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [form] = Form.useForm();

  const {
    project, saveProject, deleteProject,
  } = useProject(projectId || '');


  useEffect(() => {
    if (project) {
      form.setFieldsValue({
        ...project,
        expected_start_date: project.expected_start_date ? new dayjs(project.expected_start_date) : undefined,
        expected_end_date: project.expected_end_date ? new dayjs(project.expected_end_date) : undefined,
      });
    }
  }, [form, project]);


  if (!project) {
    return <div>Not found</div>;
  }



  const onFinish = (values) => {
    (async () => {
      try {
        const item = await saveProject({
          ...project,
          ...values,
          expected_start_date: values.expected_start_date ? values.expected_start_date.toISOString() : undefined,
          expected_end_date: values.expected_end_date ? values.expected_end_date.toISOString() : undefined,
        });
        message.success('Enregistré');
        if (item) {
          navigate('/projects');
        }
      } catch (err) {
        message.error(`Erreur: ${err}`);
      }
    })();
  };

  const onDelete = () => {
    if (projectId) {
      (async () => {
        await deleteProject();
        message.success('Supprimé');
        navigate('/projects');
      })();
    }
  };


  return (
    <Form form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ flex: 1 }}
      labelWrap
      onFinish={onFinish}
    >
      <fieldset className="box">
        <legend>Informations</legend>
        <Form.Item name="name" label="Nom" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="code" label="Code" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="agency_id" label="Agence" rules={[{ required: true }]}>
          <AgencyInput />
        </Form.Item>
        <Form.Item name="customer_code" label="Code client">
          <Input />
        </Form.Item>
        <Form.Item name="commercial_code" label="Code commercial">
          <Input />
        </Form.Item>
        <Form.Item name="phone" label="Téléphone">
          <Input />
        </Form.Item>
        <Form.Item name="address" label="Adresse">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Notes">
          <TextArea />
        </Form.Item>

      </fieldset>
      <fieldset className="box">
        <legend>Damien Zone</legend>
        <Form.Item name="margin_rate" label="Marge">
          <InputNumber suffix="%" min={0} />
        </Form.Item>
      </fieldset>
      <fieldset className="box">
        <legend>Dates</legend>
        <Form.Item name="expected_start_date" label="Date de démarrage souhaité">
          <DatePicker format={"DD/MM/YYYY"} />
        </Form.Item>
        <Form.Item name="expected_end_date" label="Date de fin souhaité">
          <DatePicker format={"DD/MM/YYYY"} />
        </Form.Item>
      </fieldset>


      <ControlBar>
        <DeleteButton onDelete={onDelete} />
        <div>
          <button type="submit" className="btn btn-primary" >Enregistrer</button>
        </div>
      </ControlBar>

    </Form>
  );
}
