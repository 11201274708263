import Icon from '@mdi/react';
import { NavLink } from 'react-router-dom';
import {
  mdiSelectGroup,
  mdiHome,
  mdiAccountHardHatOutline,
  mdiProgressWrench,
  mdiCalendarMonthOutline,
  mdiDrawing,
  mdiOfficeBuildingOutline,
  mdiStoreOutline,

} from '@mdi/js';

import logo from '../../assets/logo.png';

import './AppSidebar.scss';
import { signOut } from 'aws-amplify/auth';

export default function AppSidebar() {


  const linkClass = (baseClasses = '') => ({ isActive }) => baseClasses + (isActive ? ' is-active' : '');
  return (
    <section className="AppSidebar app-sidebar">
      <hgroup className="logo">
        <h1>
          <img src={logo} alt="logo" />
          Chronos
        </h1>
      </hgroup>
      <nav className="menu">
        {/* <NavLink to="/" className={linkClass()}>
          <span className="icon">
            <Icon path={mdiHome} size={1} />
          </span>
          <span className="text">Synthèse</span>
        </NavLink> */}
        <NavLink to="/planning" className={linkClass()}>
          <span className="icon">
            <Icon path={mdiCalendarMonthOutline} size={1} />
          </span>
          <span className="text">Planning</span>
        </NavLink>
        <NavLink to="/agencies" className={linkClass()}>
          <span className="icon">
            <Icon path={mdiStoreOutline} size={1} />
          </span>
          <span className="text">Agences</span>
        </NavLink>
        <NavLink to="/projects" className={linkClass()}>
          <span className="icon">
            <Icon path={mdiSelectGroup} size={1} />
          </span>
          <span className="text">Affaires</span>
        </NavLink>
        <NavLink to="/tasks" className={linkClass()}>
          <span className="icon">
            <Icon path={mdiDrawing} size={1} />
          </span>
          <span className="text">Tâches</span>
        </NavLink>
        <NavLink to="/resources" className={linkClass()}>
          <span className="icon">
            <Icon path={mdiAccountHardHatOutline} size={1} />
          </span>
          <span className="text">Ressources</span>
        </NavLink>
        <NavLink to="/prestations" className={linkClass()}>
          <span className="icon">
            <Icon path={mdiProgressWrench} size={1} />
          </span>
          <span className="text">Prestations</span>
        </NavLink>
      </nav>
      <nav className='mt-3'>
        <a href="/logout" className="link" onClick={signOut}>
          {/* <span className="icon">
            <Icon path={mdiHome} size={1} />
          </span> */}
          <span className="text">Déconnexion</span>
        </a>
      </nav>
      <nav className='mt-3' >
        <p style={{ fontSize: '0.6em', opacity: 0.8 }}>
          Env: {process.env.REACT_APP_ENV}
        </p>
      </nav>
    </section>
  );
}
